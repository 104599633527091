import React, {useEffect} from "react"
import {
	Grid,
	Typography,
	Box,
	Button,
	useMediaQuery,
	Paper,
	IconButton,
	Tooltip,
	ClickAwayListener,
} from "@material-ui/core"
import {CalendarModal} from "@indebted/components/AddToCalendarModal"
import {colors} from "@indebted/theme"
import {TermsOfDiscountOffer} from "@indebted/components/TermsOfDiscountOffer"
import {Eligible, NotEligible, YellowExclamationIcon, MastercardLogo, VisaLogo} from "@indebted/assets"
import {useParams} from "@reach/router"
import {track} from "@indebted/analytics"
import {makeStyles, withStyles} from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import CloseIcon from "@material-ui/icons/Close"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import {LoadingPage} from "@indebted/components/Loading"
import {paymentPlanAPI} from "@indebted/api"
import {useModal} from "@indebted/components/Modal"

import {
	CardContentLessPadding,
	CardHeaderLessPadding,
	CardHeadingTypography,
	ColoredTypography,
	ColoredTypographyWithBottomMargin,
	CurrencyTypography,
	DiscountCardContent,
	DiscountCardContentUl,
	DiscountCardHeadingTypography,
	DiscountColoredTypography,
	DiscountMessageBox,
	DiscountStyledCard,
	FailedPaymentTypography,
	GradientHeaderGrid,
	GreyStyledCard,
	GridWithBottomMargin,
	GridWithHorizontalPadding,
	PaidAmountTypography,
	StyledDiv,
	StyledTypography,
	PaddedDivider,
} from "./styles"
import {PaymentPlanInProgressPage} from "./PaymentPlanInProgress"
import {PaymentSuccessfulPage} from "./PaymentSuccessful"
import {PaymentFailedPage} from "./PaymentFailed"
import {ChargeInitiateFailedPage} from "./ChargeInitiateFailed"
import {UpdatePlanInformation} from "./UpdatePlanInformation"

const DiscountSection = ({I18n}) => {
	return (
		<DiscountStyledCard variant="outlined" isEligible={I18n.DiscountMessage.Eligible} marginTop={true}>
			<CardHeaderLessPadding
				title={
					<DiscountMessageBox>
						<Box>{I18n.DiscountMessage.Eligible ? <Eligible /> : <NotEligible />}</Box>
						<Box>
							<DiscountCardHeadingTypography color={colors.stratosLight2}>
								{I18n.DiscountMessage.Message}
							</DiscountCardHeadingTypography>
						</Box>
					</DiscountMessageBox>
				}
			/>
			{I18n.DiscountMessage.Eligible && (
				<DiscountCardContent>
					<DiscountColoredTypography color={colors.stratos}>
						{I18n.DiscountMessage.Description}
					</DiscountColoredTypography>
					{Array.isArray(I18n.DiscountMessage.Bulletpoints) && (
						<DiscountCardContentUl>
							{I18n.DiscountMessage.Bulletpoints.map((point) => (
								<li key={point}>
									<DiscountColoredTypography color={colors.stratos}>
										{point}
									</DiscountColoredTypography>
								</li>
							))}

							<TermsOfDiscountOffer linkLabel={I18n.DiscountMessage.TermsAndConditionsLabel} />
						</DiscountCardContentUl>
					)}
				</DiscountCardContent>
			)}
			<Typography variant="body2">{I18n.DiscountMessage.Footnote}</Typography>
		</DiscountStyledCard>
	)
}

// eslint-disable-next-line complexity
const ManagePaymentPlan = ({
	PaymentPlanInstalmentAmount,
	PaymentPlanFrequency,
	PaymentPlanStartDate,
	PaymentPlanEndDate,
	TotalPaymentAmount,
	I18n,
	CalendarURLs,
	PaymentMethod,
	PaidAmount,
	Currency,
	InstalmentDue,
	HasLastInstalmentFailed,
	RetryLimitReached,
	LastChargeFailedReason,
	PaymentMethodDetails,
	HasPaymentPlanOffer,
	DiscountBreakdown,
	EnablePaymentPlanSettlement,
	SettlementInProgress,
	SettlementAmount,
}) => {
	const queryParams = new URLSearchParams(location.search)
	const openCalendarModal = queryParams.get("addToCalendar")
	const [component, setComponent] = React.useState(null)

	useEffect(() => {
		setComponent(
			<ManagePaymentPlanComponent
				PaymentPlanInstalmentAmount={PaymentPlanInstalmentAmount}
				PaymentPlanFrequency={PaymentPlanFrequency}
				PaymentPlanStartDate={PaymentPlanStartDate}
				PaymentPlanEndDate={PaymentPlanEndDate}
				TotalPaymentAmount={TotalPaymentAmount}
				I18n={I18n}
				CalendarURLs={CalendarURLs}
				PaymentMethod={PaymentMethod}
				PaidAmount={PaidAmount}
				Currency={Currency}
				InstalmentDue={InstalmentDue}
				HasLastInstalmentFailed={HasLastInstalmentFailed}
				RetryLimitReached={RetryLimitReached}
				setComponent={setComponent}
				openCalendarModal={openCalendarModal}
				LastChargeFailedReason={LastChargeFailedReason}
				PaymentMethodDetails={PaymentMethodDetails}
				HasPaymentPlanOffer={HasPaymentPlanOffer}
				DiscountBreakdown={DiscountBreakdown}
				EnablePaymentPlanSettlement={EnablePaymentPlanSettlement}
				SettlementInProgress={SettlementInProgress}
				SettlementAmount={SettlementAmount}
			/>,
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const {secureCode} = useParams()
	if (I18n.DiscountMessage) {
		if (I18n.DiscountMessage.Eligible) {
			track("PaymentPlanSettlementOfferSetupSucceededWithEligibleOfferViewed", {
				SecureCode: secureCode,
			})
		} else {
			track("PaymentPlanSettlementOfferSetupSucceededWithNotEligibleOfferViewed", {
				SecureCode: secureCode,
			})
		}
	}

	return component
}

const ManagePaymentPlanComponent = ({
	PaymentPlanInstalmentAmount,
	PaymentPlanFrequency,
	PaymentPlanStartDate,
	PaymentPlanEndDate,
	I18n,
	CalendarURLs,
	PaymentMethod,
	PaidAmount,
	TotalPaymentAmount,
	Currency,
	InstalmentDue,
	HasLastInstalmentFailed,
	RetryLimitReached,
	setComponent,
	openCalendarModal,
	LastChargeFailedReason,
	PaymentMethodDetails,
	HasPaymentPlanOffer,
	DiscountBreakdown,
	EnablePaymentPlanSettlement,
	SettlementInProgress,
	SettlementAmount,
}) => {
	const [open, setOpen] = React.useState(false)
	const handleTooltipClose = () => {
		setOpen(false)
	}
	const handleTooltipOpen = () => {
		setOpen(true)
	}
	return (
		<Grid container direction="column" alignItems="center">
			<Grid container direction="column" alignItems="stretch" justifyContent="space-between">
				<ManagePaymentPlanComponentHeaderSection I18n={I18n} />

				<GridWithHorizontalPadding container item direction="column">
					<Grid container item direction="row" justifyContent="space-between">
						<StyledTypography color={colors.stratosLight}>
							{I18n.ManagePaymentPlan.AmountPaidLabel}
						</StyledTypography>
					</Grid>
					<Grid container item direction="row" alignItems="flex-end">
						<PaidAmountTypography color={colors.stratos}>{PaidAmount}</PaidAmountTypography>
						<CurrencyTypography color={colors.stratos}>{Currency}</CurrencyTypography>
						<ClickAwayListener onClickAway={handleTooltipClose}>
							<StyledTooltip
								PopperProps={{
									disablePortal: true,
								}}
								arrow
								placement="top"
								onClose={handleTooltipClose}
								open={open}
								disableFocusListener
								disableHoverListener
								disableTouchListener
								title={
									<>
										<Typography color="inherit">
											{I18n.ManagePaymentPlan.AmountPaidTooltip}
										</Typography>
									</>
								}
							>
								<IconButton
									style={{
										marginBottom: "-3px",
										marginLeft: "-6px",
									}}
									aria-label="close"
									onMouseOver={handleTooltipOpen}
									onMouseLeave={handleTooltipClose}
									onClick={handleTooltipOpen}
								>
									<InfoOutlinedIcon htmlColor="#020452" fontSize="small" />
								</IconButton>
							</StyledTooltip>
						</ClickAwayListener>
					</Grid>
					<Grid container item direction="row" justifyContent="space-between">
						<ColoredTypography variant="body2" color={colors.stratosLight}>
							{I18n.ManagePaymentPlan.AmountRemainingAndPaidInstalmentsText}
						</ColoredTypography>
					</Grid>
					{I18n.DiscountMessage && <DiscountSection I18n={I18n} />}
					<InstalmentCard
						InstalmentDue={InstalmentDue}
						I18n={I18n}
						setComponent={setComponent}
						HasLastInstalmentFailed={HasLastInstalmentFailed}
						RetryLimitReached={RetryLimitReached}
						LastChargeFailedReason={LastChargeFailedReason}
						EnablePaymentPlanSettlement={EnablePaymentPlanSettlement}
						SettlementInProgress={SettlementInProgress}
						SettlementAmount={SettlementAmount}
					/>
					<PaymentPlanDetailsCard
						CalendarURLs={CalendarURLs}
						openCalendarModal={openCalendarModal}
						PaymentPlanInstalmentAmount={PaymentPlanInstalmentAmount}
						PaymentPlanFrequency={PaymentPlanFrequency}
						PaymentPlanStartDate={PaymentPlanStartDate}
						PaymentPlanEndDate={PaymentPlanEndDate}
						TotalPaymentAmount={TotalPaymentAmount}
						I18n={I18n}
						setComponent={setComponent}
						InstalmentDue={InstalmentDue}
						PaymentMethod={PaymentMethod}
						PaymentMethodDetails={PaymentMethodDetails}
						HasPaymentPlanOffer={HasPaymentPlanOffer}
						DiscountBreakdown={DiscountBreakdown}
					/>
					<ColoredTypography variant="body2" color={colors.stratosLight} style={{padding: "20px 5px 0px"}}>
						{I18n.ManagePaymentPlan.EndDateDisclaimerText}
					</ColoredTypography>
				</GridWithHorizontalPadding>
			</Grid>
		</Grid>
	)
}

const FailedInstalmentCard = ({
	InstalmentDue,
	I18n,
	setComponent,
	HasLastInstalmentFailed,
	LastChargeFailedReason,
	SettlementInProgress,
}) => {
	return (
		<GreyStyledCard variant="outlined">
			<CardHeaderLessPadding
				title={
					<Grid container item direction="row" alignItems="flex-end" justifyContent="space-between">
						<FailedPaymentTypography color={colors.stratos}>
							{I18n.ManagePaymentPlan.FailedInstalmentHeading}
						</FailedPaymentTypography>
						<YellowExclamationIcon />
					</Grid>
				}
			/>
			<CardContentLessPadding>
				{LastChargeFailedReason && (
					<Grid container item direction="row" justifyContent="space-between">
						<StyledDiv style={{marginBottom: 10}}>
							<ColoredTypography variant="body2" color={colors.stratos}>
								{LastChargeFailedReason}
							</ColoredTypography>
						</StyledDiv>
					</Grid>
				)}
				<Grid container item direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.errorRed}>
							{I18n.ManagePaymentPlan.InstalmentDueAmountLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.errorRed}>
							{InstalmentDue.Amount}
						</ColoredTypography>
					</StyledDiv>
				</Grid>
				<PaddedDivider />
				<Grid container item direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.errorRed}>
							{I18n.ManagePaymentPlan.FailedInstalmentAttemptedAtLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.errorRed}>
							{InstalmentDue.PaymentDate}
						</ColoredTypography>
					</StyledDiv>
				</Grid>
				<PaddedDivider />
				{InstalmentDue.InProgress && (
					<Grid container item direction="row" justifyContent="center" style={{margin: "15px 1px"}}>
						<CardHeadingTypography color={colors.indebtedPrimary}>
							{I18n.ManagePaymentPlan.InstalmentDueInProgressLabel}
						</CardHeadingTypography>
						<ColoredTypography color={colors.stratosLight2}>
							{I18n.ManagePaymentPlan.InstalmentDueInProgressText}
						</ColoredTypography>
					</Grid>
				)}
				<Grid container item direction="row" justifyContent="center" style={{marginBottom: "10px"}}>
					<PayInstalmentModal
						I18n={I18n}
						disabled={InstalmentDue.InProgress || SettlementInProgress}
						setComponent={setComponent}
						instalmentID={InstalmentDue.ID}
						InstalmentDue={InstalmentDue}
						HasLastInstalmentFailed={HasLastInstalmentFailed}
						showAsLink={false}
					/>
				</Grid>
			</CardContentLessPadding>
		</GreyStyledCard>
	)
}

const NextInstalmentCard = ({InstalmentDue, I18n, setComponent, HasLastInstalmentFailed, SettlementInProgress}) => {
	return (
		<>
			<GreyStyledCard variant="outlined">
				<CardHeaderLessPadding
					title={
						<Grid container item direction="row" alignItems="flex-end" justifyContent="space-between">
							<FailedPaymentTypography color={colors.stratos}>
								{I18n.ManagePaymentPlan.ExtraPaymentHeading}
							</FailedPaymentTypography>
						</Grid>
					}
				/>
				{InstalmentDue.InProgress && (
					<>
						<Grid
							container
							item
							direction="row"
							justifyContent="center"
							style={{margin: "0px 1px 15px 1px"}}
						>
							<CardHeadingTypography color={colors.indebtedPrimary}>
								{I18n.ManagePaymentPlan.InstalmentDueInProgressLabel}
							</CardHeadingTypography>
							<ColoredTypography color={colors.stratosLight2} align="center">
								{I18n.ManagePaymentPlan.InstalmentDueInProgressText}
							</ColoredTypography>
						</Grid>
					</>
				)}

				<CardContentLessPadding>
					<Grid container item direction="row" justifyContent="center" style={{marginBottom: "10px"}}>
						<PayInstalmentModal
							I18n={I18n}
							disabled={InstalmentDue.InProgress || SettlementInProgress}
							setComponent={setComponent}
							instalmentID={InstalmentDue.ID}
							InstalmentDue={InstalmentDue}
							HasLastInstalmentFailed={HasLastInstalmentFailed}
							showAsLink={false}
						/>
					</Grid>
				</CardContentLessPadding>
			</GreyStyledCard>
		</>
	)
}

const NextInstalmentCardWithSettlement = ({
	InstalmentDue,
	I18n,
	setComponent,
	HasLastInstalmentFailed,
	SettlementInProgress,
	SettlementAmount,
}) => {
	return (
		<>
			<GreyStyledCard variant="outlined">
				<CardHeaderLessPadding
					title={
						<Grid container item direction="row" alignItems="flex-end" justifyContent="center">
							<FailedPaymentTypography color={colors.stratos}>
								{I18n.ManagePaymentPlan.SettlementPaymentHeading}
							</FailedPaymentTypography>
						</Grid>
					}
				/>
				{InstalmentDue.InProgress && (
					<>
						<Grid
							container
							item
							direction="row"
							justifyContent="center"
							style={{margin: "0px 1px 15px 1px"}}
						>
							<CardHeadingTypography color={colors.indebtedPrimary}>
								{I18n.ManagePaymentPlan.InstalmentDueInProgressLabel}
							</CardHeadingTypography>
							<ColoredTypography color={colors.stratosLight2} align="center">
								{I18n.ManagePaymentPlan.InstalmentDueInProgressText}
							</ColoredTypography>
						</Grid>
					</>
				)}

				<CardContentLessPadding>
					<Grid container item direction="row" justifyContent="center" style={{marginBottom: "10px"}}>
						<PaySettlementModal
							I18n={I18n}
							disabled={InstalmentDue.InProgress || SettlementInProgress}
							setComponent={setComponent}
							SettlementAmount={SettlementAmount}
						/>
						<PayInstalmentModal
							I18n={I18n}
							disabled={InstalmentDue.InProgress || SettlementInProgress}
							setComponent={setComponent}
							instalmentID={InstalmentDue.ID}
							InstalmentDue={InstalmentDue}
							HasLastInstalmentFailed={HasLastInstalmentFailed}
							showAsLink={true}
						/>
					</Grid>
				</CardContentLessPadding>
			</GreyStyledCard>
		</>
	)
}

const ManagePaymentPlanComponentHeaderSection = ({I18n}) => {
	return (
		<GradientHeaderGrid padded container item direction="column" align="left">
			<Grid item>
				<ColoredTypographyWithBottomMargin variant="h6" color={colors.stratos} className="clarity-mask">
					{I18n.ManagePaymentPlan.PageHeading}
				</ColoredTypographyWithBottomMargin>
				<ColoredTypography variant="body2" color={colors.stratos} className="clarity-mask">
					{I18n.ManagePaymentPlan.PageSubHeading}
				</ColoredTypography>
			</Grid>
		</GradientHeaderGrid>
	)
}

const InstalmentCard = ({
	InstalmentDue,
	I18n,
	setComponent,
	HasLastInstalmentFailed,
	RetryLimitReached,
	LastChargeFailedReason,
	EnablePaymentPlanSettlement,
	SettlementInProgress,
	SettlementAmount,
}) => {
	let showFailedInstalment = HasLastInstalmentFailed && InstalmentDue

	if (RetryLimitReached) {
		return <></>
	}

	if (showFailedInstalment) {
		return (
			<FailedInstalmentCard
				InstalmentDue={InstalmentDue}
				I18n={I18n}
				setComponent={setComponent}
				HasLastInstalmentFailed={HasLastInstalmentFailed}
				LastChargeFailedReason={LastChargeFailedReason}
				SettlementInProgress={SettlementInProgress}
			/>
		)
	}

	if (EnablePaymentPlanSettlement) {
		return (
			<NextInstalmentCardWithSettlement
				InstalmentDue={InstalmentDue}
				I18n={I18n}
				setComponent={setComponent}
				HasLastInstalmentFailed={HasLastInstalmentFailed}
				SettlementInProgress={SettlementInProgress}
				SettlementAmount={SettlementAmount}
			/>
		)
	}

	return (
		<NextInstalmentCard
			InstalmentDue={InstalmentDue}
			I18n={I18n}
			setComponent={setComponent}
			HasLastInstalmentFailed={HasLastInstalmentFailed}
			SettlementInProgress={SettlementInProgress}
		/>
	)
}

// eslint-disable-next-line complexity
const PaymentPlanDetailsCard = ({
	CalendarURLs,
	openCalendarModal,
	PaymentPlanInstalmentAmount,
	PaymentPlanFrequency,
	PaymentPlanEndDate,
	TotalPaymentAmount,
	I18n,
	setComponent,
	InstalmentDue,
	PaymentMethod,
	PaymentMethodDetails,
	HasPaymentPlanOffer,
	DiscountBreakdown,
}) => {
	const InstalmentInProgressModal = useModal()

	const onUpdatePlanClick = () => {
		if (InstalmentDue.InProgress) {
			InstalmentInProgressModal.Open()
			return
		}
		setComponent(<UpdatePlanInformation I18n={I18n} HasPaymentPlanOffer={HasPaymentPlanOffer} />)
		window.scrollTo(0, 0)
	}

	return (
		<GreyStyledCard variant="outlined">
			<InstalmentInProgressModal.Component>
				<Grid container direction="row" spacing={3} justifyContent="flex-start" alignItems="center">
					<Grid item xs={9} sm={10} style={{paddingLeft: "12px"}}>
						<ColoredTypography
							id="modal-title"
							color={colors.errorRed}
							style={{fontSize: "18px", fontWeight: 700}}
						>
							{I18n.ManagePaymentPlan.CannotUpdatePlanModal.Title}
						</ColoredTypography>
					</Grid>
					<Grid item xs={3} sm={2} style={{display: "flex", justifyContent: "flex-end"}}>
						<InstalmentInProgressModal.CloseIconButton />
					</Grid>
				</Grid>
				<Grid item style={{paddingLeft: "6px"}}>
					<ColoredTypography color={colors.stratos}>
						{I18n.ManagePaymentPlan.CannotUpdatePlanModal.Text}
					</ColoredTypography>
				</Grid>
			</InstalmentInProgressModal.Component>
			<CardHeaderLessPadding
				title={
					<Grid container item direction="row" justifyContent="space-between">
						<CardHeadingTypography color={colors.stratos}>
							{I18n.ManagePaymentPlan.PaymentPlanDetailsHeading}
						</CardHeadingTypography>
						<Button variant="outlined" onClick={onUpdatePlanClick}>
							{I18n.ManagePaymentPlan.UpdatePaymentPlanButtonLabel}
						</Button>
					</Grid>
				}
			/>
			<CardContentLessPadding>
				<Grid container item direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{I18n.ManagePaymentPlan.PlanLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{PaymentPlanInstalmentAmount} {PaymentPlanFrequency}
						</ColoredTypography>
					</StyledDiv>
				</Grid>
				<PaddedDivider />
				<Grid container item direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{I18n.ManagePaymentPlan.NextInstalmentDueDateLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{InstalmentDue.PaymentDate}
						</ColoredTypography>
					</StyledDiv>
				</Grid>
				<PaddedDivider />
				<Grid container item direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{I18n.ManagePaymentPlan.EndDateLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{PaymentPlanEndDate}
						</ColoredTypography>
					</StyledDiv>
				</Grid>
				<PaddedDivider />
				<Grid container item direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{I18n.ManagePaymentPlan.PaymentMethodLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{PaymentMethod}
						</ColoredTypography>
					</StyledDiv>
				</Grid>

				{PaymentMethodDetails && PaymentMethodDetails.Last4 && (
					<>
						<PaddedDivider />
						<GridWithBottomMargin container item direction="row" justifyContent="space-between">
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{I18n.ManagePaymentPlan.PaymentMethodDetailsLabel}
								</ColoredTypography>
							</StyledDiv>
							<StyledDiv>
								{PaymentMethodDetails.CardBrand === "visa" && <VisaLogo />}
								{PaymentMethodDetails.CardBrand === "mastercard" && <MastercardLogo />}
								<ColoredTypography
									style={{marginLeft: 15}}
									variant="body2"
									color={colors.stratosLight2}
								>
									•••• {PaymentMethodDetails.Last4}
								</ColoredTypography>
							</StyledDiv>
						</GridWithBottomMargin>
					</>
				)}
				<PaddedDivider />
				<Grid container item direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{I18n.ManagePaymentPlan.TotalPaymentAmountLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{TotalPaymentAmount}
						</ColoredTypography>
					</StyledDiv>
				</Grid>
				<PaddedDivider />
			</CardContentLessPadding>
			{DiscountBreakdown && (
				<>
					<CardHeaderLessPadding
						title={
							<Grid container item direction="row" justifyContent="space-between">
								<CardHeadingTypography color={colors.stratos}>
									{I18n.ManagePaymentPlan.DiscountBreakdownTable.TableHeading}
								</CardHeadingTypography>
							</Grid>
						}
					/>
					<CardContentLessPadding>
						<Grid container item direction="row" justifyContent="space-between">
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{I18n.ManagePaymentPlan.DiscountBreakdownTable.DiscountRateLabel}
								</ColoredTypography>
							</StyledDiv>
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{DiscountBreakdown.DiscountRate}
								</ColoredTypography>
							</StyledDiv>
						</Grid>
						<PaddedDivider />
						<Grid container item direction="row" justifyContent="space-between">
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{I18n.ManagePaymentPlan.DiscountBreakdownTable.TotalAmountLabel}
								</ColoredTypography>
							</StyledDiv>
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{DiscountBreakdown.TotalAmount}
								</ColoredTypography>
							</StyledDiv>
						</Grid>
						<PaddedDivider />
						<Grid container item direction="row" justifyContent="space-between">
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{I18n.ManagePaymentPlan.DiscountBreakdownTable.NumberOfPaymentsLabel}
								</ColoredTypography>
							</StyledDiv>
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{DiscountBreakdown.NumberOfPayments}
								</ColoredTypography>
							</StyledDiv>
						</Grid>
						<PaddedDivider />
						<Grid container item direction="row" justifyContent="space-between">
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{I18n.ManagePaymentPlan.DiscountBreakdownTable.RecurringPaymentsAmountLabel}
								</ColoredTypography>
							</StyledDiv>
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{DiscountBreakdown.RecurringPaymentsAmount}
								</ColoredTypography>
							</StyledDiv>
						</Grid>
						<PaddedDivider />
						<Grid container item direction="row" justifyContent="space-between">
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{I18n.ManagePaymentPlan.DiscountBreakdownTable.LastPaymentAmountLabel}
								</ColoredTypography>
							</StyledDiv>
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{DiscountBreakdown.LastPaymentAmount}
								</ColoredTypography>
							</StyledDiv>
						</Grid>
						<PaddedDivider />
					</CardContentLessPadding>
				</>
			)}
			<Grid container item direction="row" justifyContent="center" style={{marginBottom: "25px"}}>
				{CalendarURLs && (
					<CalendarModal
						CalendarURLs={CalendarURLs}
						openCalendarModal={openCalendarModal}
						headingLabel={I18n.ManagePaymentPlan.AddToCalendarModalHeading}
						buttonLabel={I18n.ManagePaymentPlan.AddToCalendarButtonLabel}
					/>
				)}
			</Grid>
		</GreyStyledCard>
	)
}

const PaySettlementModal = ({I18n, disabled, setComponent, SettlementAmount}) => {
	const {paymentPlanID} = useParams()
	const [open, setOpen] = React.useState(false)
	const [buttonDisable, setButtonDisable] = React.useState(false)
	const mobile = useMediaQuery("(max-width:599px)")
	const useStyles = makeStyles((theme) => {
		return {
			paper: {
				position: "relative",
				width: (mobile && "80%") || "400px",
				backgroundColor: theme.palette.background.paper,
				boxShadow: theme.shadows[5],
				padding: theme.spacing(2, 4, 2),
				outline: "none",
			},
			root: {
				minWidth: 275,
			},
		}
	})
	const classes = useStyles()
	const [modalStyle] = React.useState(getModalStyle)

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	// eslint-disable-next-line no-unused-vars
	const instalmentResultStatusComponent = (status) => {
		const map = {
			Submitted: PaymentPlanInProgressPage,
			Failed: PaymentFailedPage,
		}
		return map[status]
	}

	function payNow() {
		setButtonDisable(true)

		paymentPlanAPI
			.settlePaymentPlan({
				paymentPlanId: paymentPlanID,
			})
			.then(() => {
				setComponent(<LoadingPage />)
				return paymentPlanAPI.pollPayInstalmentConfirmation(paymentPlanID).then((instalmentResult) => {
					if (instalmentResult.Status == "Succeeded") {
						window.location.reload()
						return
					}
					const Component = instalmentResultStatusComponent(instalmentResult.Status)
					setComponent(
						<Component
							I18n={instalmentResult.I18n}
							amount={SettlementAmount}
							reason={instalmentResult.FailureReason}
						/>,
					)
				})
			})
			.catch(() => {
				setComponent(<ChargeInitiateFailedPage I18n={I18n} />)
			})
	}

	return (
		<>
			<Button
				style={{
					borderRadius: "4px",
					padding: "6px 16px",
					marginTop: "10px",
					width: "100%",
				}}
				onClick={handleOpen}
				type="button"
				variant="contained"
				disabled={disabled}
			>
				{I18n.ManagePaymentPlan.SettlementPaymentButtonLabel}
			</Button>
			<Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
				<Paper style={modalStyle} className={classes.paper}>
					<Grid
						container
						direction="row"
						spacing={3}
						justifyContent="flex-end"
						alignItems="center"
						style={{marginBottom: "10px"}}
					>
						<Grid item xs={9} sm={10} style={{paddingLeft: "12px"}}>
							<ColoredTypography
								id="modal-title"
								color={colors.stratosLight2}
								style={{fontSize: "18px", fontWeight: 700}}
							>
								{I18n.ManagePaymentPlan.SettlementPaymentModalHeading}
							</ColoredTypography>
						</Grid>
						<Grid item xs={3} sm={2} style={{display: "flex", justifyContent: "flex-end"}}>
							<IconButton aria-label="close" className={classes.margin} onClick={handleClose}>
								<CloseIcon htmlColor="rgba(50,50,50,0.45)" />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item style={{paddingLeft: "6px"}}>
						<ColoredTypography color={colors.stratosLight2}>
							{I18n.ManagePaymentPlan.SettlementPaymentModalDescription}
						</ColoredTypography>
					</Grid>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="center"
						style={{marginTop: "30px"}}
					>
						<Button
							style={{borderRadius: "4px", padding: "6px 16px", marginTop: "10px", width: "100%"}}
							type="button"
							onClick={payNow}
							variant="contained"
							disabled={buttonDisable}
						>
							{I18n.ManagePaymentPlan.SettlementPaymentModalPayButtonLabel}
						</Button>
						<Grid item>
							<Button
								style={{borderRadius: "35px", paddingBottom: "10px"}}
								type="button"
								onClick={handleClose}
								variant="text"
							>
								{I18n.ManagePaymentPlan.SettlementPaymentModalCancelButtonLabel}
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Modal>
		</>
	)
}

const PayInstalmentModal = ({
	I18n,
	disabled,
	setComponent,
	instalmentID,
	InstalmentDue,
	HasLastInstalmentFailed,
	showAsLink,
}) => {
	const {paymentPlanID} = useParams()
	const [open, setOpen] = React.useState(false)
	const [buttonDisable, setButtonDisable] = React.useState(false)
	const mobile = useMediaQuery("(max-width:599px)")
	const useStyles = makeStyles((theme) => {
		return {
			paper: {
				position: "relative",
				width: (mobile && "80%") || "400px",
				backgroundColor: theme.palette.background.paper,
				boxShadow: theme.shadows[5],
				padding: theme.spacing(2, 4, 2),
				outline: "none",
			},
			root: {
				minWidth: 275,
			},
		}
	})
	const classes = useStyles()
	const [modalStyle] = React.useState(getModalStyle)

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const instalmentResultStatusComponent = (status) => {
		const map = {
			Submitted: PaymentPlanInProgressPage,
			Succeeded: PaymentSuccessfulPage,
			Failed: PaymentFailedPage,
		}
		return map[status]
	}

	function payNow() {
		setButtonDisable(true)

		paymentPlanAPI
			.payInstalment({
				paymentPlanId: paymentPlanID,
				instalmentId: instalmentID,
			})
			.then(() => {
				setComponent(<LoadingPage />)
				paymentPlanAPI
					.pollPayInstalmentConfirmation(paymentPlanID)
					.then((instalmentResult) => {
						const Component = instalmentResultStatusComponent(instalmentResult.Status)
						setComponent(
							<Component
								I18n={instalmentResult.I18n}
								amount={InstalmentDue.Amount}
								reason={instalmentResult.FailureReason}
							/>,
						)
					})
					.catch(() => {
						setComponent(<ChargeInitiateFailedPage I18n={I18n} />)
					})
			})
			.catch(() => {
				setComponent(<ChargeInitiateFailedPage I18n={I18n} />)
			})
	}

	return (
		<>
			<Button
				style={{
					borderRadius: "4px",
					padding: "6px 16px",
					marginTop: "10px",
					width: "100%",
				}}
				onClick={handleOpen}
				type="button"
				variant={showAsLink ? "outlined" : "contained"}
				disabled={disabled}
			>
				{HasLastInstalmentFailed
					? I18n.ManagePaymentPlan.FailedInstalmentButtonLabel
					: I18n.ManagePaymentPlan.ExtraPaymentButtonLabel}
			</Button>
			<Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
				<Paper style={modalStyle} className={classes.paper}>
					<Grid
						container
						direction="row"
						spacing={3}
						justifyContent="flex-end"
						alignItems="center"
						style={{marginBottom: "10px"}}
					>
						<Grid item xs={9} sm={10} style={{paddingLeft: "12px"}}>
							<ColoredTypography
								id="modal-title"
								color={colors.stratosLight2}
								style={{fontSize: "18px", fontWeight: 700}}
							>
								{HasLastInstalmentFailed
									? I18n.ManagePaymentPlan.ConfirmPaymentModalHeading
									: I18n.ManagePaymentPlan.ExtraPaymentModalHeading}
							</ColoredTypography>
						</Grid>
						<Grid item xs={3} sm={2} style={{display: "flex", justifyContent: "flex-end"}}>
							<IconButton aria-label="close" className={classes.margin} onClick={handleClose}>
								<CloseIcon htmlColor="rgba(50,50,50,0.45)" />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item style={{paddingLeft: "6px"}}>
						<ColoredTypography color={colors.stratosLight2}>
							{!HasLastInstalmentFailed && (
								<>
									{I18n.ManagePaymentPlan.ExtraPaymentModalDescription} <br />
									<br />
								</>
							)}

							{I18n.ManagePaymentPlan.ConfirmPaymentModalText}
						</ColoredTypography>
					</Grid>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="center"
						style={{marginTop: "30px"}}
					>
						<Button
							style={{borderRadius: "4px", padding: "6px 16px", marginTop: "10px", width: "100%"}}
							type="button"
							onClick={payNow}
							variant="contained"
							disabled={buttonDisable}
						>
							{I18n.ManagePaymentPlan.ConfirmPaymentModalPayButtonLabel}
						</Button>
						<Grid item>
							<Button
								style={{borderRadius: "35px", paddingBottom: "10px"}}
								type="button"
								onClick={handleClose}
								variant="text"
							>
								{I18n.ManagePaymentPlan.ConfirmPaymentModalCancelButtonLabel}
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Modal>
		</>
	)
}

function getModalStyle() {
	return {
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
		borderRadius: "24px",
		paddingBottom: "20px",
	}
}

const StyledTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "#fff",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid lightgray",
		boxShadow: "10px 10px 10px lightgray;",
		margin: "0px",
	},
	arrow: {
		color: "#f5f5f9",
	},
}))(Tooltip)

export {ManagePaymentPlan}
