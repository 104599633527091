import React, {useState} from "react"
import {Modal, useMediaQuery, Paper, IconButton} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import {makeStyles} from "@material-ui/core/styles"

const useModal = ({onClose} = {}) => {
	const [isOpen, setIsOpen] = useState(false)
	const mobile = useMediaQuery("(max-width:599px)")
	const useStyles = makeStyles((theme) => {
		return {
			paper: {
				position: "relative",
				width: mobile ? "90%" : "400px",
				maxHeight: "95%",
				backgroundColor: theme.palette.background.paper,
				boxShadow: theme.shadows[5],
				padding: theme.spacing(2, 4, 2),
				outline: "none",
				overflow: `auto`,
			},
		}
	})

	const classes = useStyles()

	const handleClose = (event, reason) => {
		onClose ? onClose(event, reason) : null
		setIsOpen(false)
	}

	return {
		Open: () => {
			setIsOpen(true)
		},
		Close: handleClose,
		// eslint-disable-next-line react/display-name
		Component: ({children, disableBackdropClick = false, disableEscapeKeyDown = false}) => (
			<Modal
				open={isOpen}
				onClose={handleClose}
				disableBackdropClick={disableBackdropClick}
				disableEscapeKeyDown={disableEscapeKeyDown}
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
			>
				<Paper
					style={{
						top: `50%`,
						left: `50%`,
						transform: `translate(-50%, -50%)`,
						borderRadius: "24px",
						paddingBottom: "20px",
					}}
					className={classes.paper}
				>
					{children}
				</Paper>
			</Modal>
		),
		// eslint-disable-next-line react/display-name
		CloseIconButton: ({disabled = false}) => (
			<IconButton aria-label="close" onClick={handleClose} disabled={disabled}>
				<CloseIcon htmlColor="rgba(50,50,50,0.45)" />
			</IconButton>
		),
	}
}

export {useModal}
