import React, {useState} from "react"

import {Question} from "./Question"

function Steps({questions, onSubmit, id}) {
	const [Component, setComponent] = useState(<Question question={questions[0]} answers={[]} onAnswer={onAnswer} />)

	function onAnswer(option, answers) {
		if (option.NextQuestion) {
			setComponent(
				<Question
					question={questions.find((question) => question.Number === option.NextQuestion)}
					answers={answers}
					onAnswer={onAnswer}
				/>,
			)
		} else {
			onSubmit({answers, id})
		}
	}

	return Component
}

export {Steps}
