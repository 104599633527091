import React from "react"
import {Page} from "@indebted/components/Page"
import {Grid, Typography} from "@material-ui/core"
function Rejected() {
	return (
		<Page>
			<Grid item>
				<Typography variant="body2" align="center">
					Thank you for going through this with me. Right now it doesn&apos;t seem like a payment arrangement
					is the right solution for you.
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="body2" align="center">
					In circumstances like yours what we recommend is that you go seek some independent debt advice.
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="body2" align="center">
					We&apos;ll place your account on hold for one month to give you a bit of breathing space and give
					you the opportunity to seek some free and impartial advice.
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="h6" align="center">
					StepChange
				</Typography>
				<Typography variant="body2" align="center">
					0800 138 1111
				</Typography>
				<Typography variant="body2" align="center">
					www.stepchange.org
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="h6" align="center">
					National Debtline
				</Typography>
				<Typography variamnt="body2" align="center">
					0800 808 4000
				</Typography>
				<Typography variant="body2" align="center">
					www.nationaldebtline.org
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="h6" align="center">
					Citizens Advice Bureau
				</Typography>
				<Typography variant="body2" align="center">
					www.citizanadvice.org.uk
				</Typography>
			</Grid>
		</Page>
	)
}

export {Rejected}
