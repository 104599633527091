import React, {useState} from "react"
import {useNotification} from "@indebted/components/Notification"

import {Api} from "./api"
import {FormInputs} from "./FormInputs"
import {FormConfirmation} from "./FormConfirmation"

function Form({stripe, onSubmit, i18n, termsOfDiscountOffer, paymentMethodSelectionLink}) {
	const {notification} = useNotification()
	const [submitting, setSubmitting] = useState(false)
	const [confirmation, setConfirmation] = useState(false)
	const [form, setForm] = useState({
		Email: "",
		Name: "",
		SortCode: "",
		SortCodeDirty: false,
		AccountNumber: "",
		AccountNumberDirty: false,
		AddressLine1: "",
		AddressLine2: "",
		City: "",
		Postcode: "",
		PayerAgreement: false,
		Confirmation: false,
	})

	function confirm() {
		const emailValidationRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (form.Name.trim() == "" || !form.Email.match(emailValidationRegex)) {
			notification.error(i18n.FormErrorsEmailNameValidation, 5000)
			return
		}

		setSubmitting(true)
		onSubmit({
			stripeAPI: Api({
				stripeSDK: stripe,
				form,
			}),
		}).catch(() => setSubmitting(false))
	}

	function handleInput(name) {
		return ({target: {value}}) => {
			setForm({...form, [name]: value})
		}
	}

	function handleCheckboxInput(name) {
		return ({target: {checked}}) => {
			setForm({...form, [name]: checked})
		}
	}

	function toggleConfirmation() {
		setConfirmation(!confirmation)
	}

	if (confirmation) {
		return (
			<FormConfirmation
				form={form}
				i18n={i18n}
				submitting={submitting}
				onSubmit={confirm}
				onModify={toggleConfirmation}
			/>
		)
	}

	return (
		<FormInputs
			form={form}
			handleInput={handleInput}
			handleCheckboxInput={handleCheckboxInput}
			onSubmit={toggleConfirmation}
			i18n={i18n}
			termsOfDiscountOffer={termsOfDiscountOffer}
			paymentMethodSelectionLink={paymentMethodSelectionLink}
		/>
	)
}

export {Form}
