import React from "react"
import {Page} from "@indebted/components/Page"
import {DiscountMessage} from "@indebted/components/DiscountMessage"
import {Grid, Typography} from "@material-ui/core"
import {StripeDebitCardWithEFTAForm} from "@indebted/stripe"

function DebitCardWithEFTAForm({
	i18n,
	discountMessage,
	termsOfDiscountOffer,
	locale,
	onSubmitEFTA,
	publishableKey,
	requiresPostcode = false,
	acceptedCardsList,
	morePaymentMethodsAvailable,
	walletPayMethods,
	providerData,
	paymentPlanID,
	requestESIGNConsent,
	esignConsent,
}) {
	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item>
					<DiscountMessage discountMessage={discountMessage} />
				</Grid>
				<Grid item>
					<Typography variant="h6">{i18n.Header}</Typography>
				</Grid>
				<Grid item>
					<Typography>{i18n.Title}</Typography>
				</Grid>

				<Grid item>
					<StripeDebitCardWithEFTAForm
						i18n={i18n}
						termsOfDiscountOffer={termsOfDiscountOffer}
						locale={locale}
						onSubmitEFTA={onSubmitEFTA}
						publishableKey={publishableKey}
						hidePostalCode={!requiresPostcode}
						acceptedCardsList={acceptedCardsList}
						morePaymentMethodsAvailable={morePaymentMethodsAvailable}
						walletPayMethods={walletPayMethods}
						paymentMethodSelectionLink={`PaymentMethodSelection${location.search}`}
						providerData={providerData}
						paymentPlanID={paymentPlanID}
						requestESIGNConsent={requestESIGNConsent}
						esignConsent={esignConsent}
					/>
				</Grid>
			</Grid>
		</Page>
	)
}

export {DebitCardWithEFTAForm}
