import {Box, Button, FormControl, Grid, InputLabel, Link, OutlinedInput, Typography} from "@material-ui/core"
import styled from "styled-components"
import React, {useEffect} from "react"

function FormConfirmation({form, i18n, submitting, onSubmit, onModify}) {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	function submit(event) {
		event.preventDefault()

		onSubmit()
	}

	return (
		<form onSubmit={submit}>
			<ModifyBoxTitle display="flex" justifyContent="space-between">
				<Typography component="span">Confirm your details</Typography>
				<ModifyButton variant="text" component="span" onClick={onModify}>
					Modify
				</ModifyButton>
			</ModifyBoxTitle>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item>
					<FormControl variant="outlined" fullWidth={true}>
						<InputLabel htmlFor="fullname">{i18n.FormFullNameLabel}</InputLabel>
						<OutlinedInput
							id="fullname"
							type="text"
							label={i18n.FormFullNameLabel}
							value={form.Name}
							variant="outlined"
							disabled
						/>
					</FormControl>
				</Grid>
				<Grid item>
					<FormControl variant="outlined" fullWidth={true}>
						<InputLabel htmlFor="email">{i18n.FormEmailLabel}</InputLabel>
						<OutlinedInput
							id="email"
							label={i18n.FormEmailLabel}
							type="email"
							value={form.Email}
							variant="outlined"
							disabled
						/>
					</FormControl>
				</Grid>
				<Grid item>
					<FormControl variant="outlined" fullWidth={true}>
						<InputLabel htmlFor="sort-code">{i18n.FormSortCodeLabel}</InputLabel>
						<OutlinedInput
							id="sort-code"
							label={i18n.FormSortCodeLabel}
							type="text"
							value={form.SortCode}
							variant="outlined"
							disabled
						/>
					</FormControl>
				</Grid>
				<Grid item>
					<FormControl variant="outlined" fullWidth={true}>
						<InputLabel htmlFor="account-number">{i18n.FormAccountNumberLabel}</InputLabel>
						<OutlinedInput
							id="account-number"
							label={i18n.FormAccountNumberLabel}
							type="text"
							value={form.AccountNumber}
							variant="outlined"
							disabled
						/>
					</FormControl>
				</Grid>
				<Grid item>
					<Button fullWidth type="submit" disabled={submitting}>
						{i18n.FormConfirmButtonLabel}
					</Button>
				</Grid>
				<Grid item>
					<Typography variant="caption" color="textSecondary" component="p">
						{i18n.FormDirectDebitExplanationText}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="caption" color="textSecondary" component="p">
						{i18n.FormDirectDebitGuaranteeText}{" "}
						<Link href={i18n.FormDirectDebitGuaranteeLink} target="_blank" rel="noopener noreferrer">
							{i18n.FormDirectDebitGuaranteeLinkText}
						</Link>
					</Typography>
				</Grid>

				<Grid item>
					<Typography variant="caption" color="textSecondary" component="p">
						{i18n.InDebtedAddressText}
					</Typography>
					<Link href={"mailto:" + i18n.CustomerSupportEmailLink}>{i18n.CustomerSupportEmailLink}</Link>
				</Grid>
			</Grid>
		</form>
	)
}

const ModifyButton = styled(Button)`
	padding: 0;
	min-width: 0;
`

const ModifyBoxTitle = styled(Box)`
	padding: 0px 0px 15px 0px;
`

export {FormConfirmation}
