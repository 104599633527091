const typography = {
	h6: {
		fontWeight: 700,
	},
	button: {
		textTransform: "none",
	},
}

export {typography}
