import React, {useEffect, useState} from "react"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"
import {loadStripe} from "@stripe/stripe-js"
import {track} from "@indebted/analytics"

const errorTitle = "Failed contacting payment provider"
const errorMessage = (
	<>
		We apologize for the inconvenience.
		<br />
		Please try again.
	</>
)

function withStripe(Component, betas = []) {
	return function WithStripe({publishableKey, ...props}) {
		const [stripe, setStripe] = useState(null)
		const [error, setError] = useState(null)

		useEffect(() => {
			loadStripe(publishableKey, {betas})
				.then((stripe) => {
					setStripe(stripe)
				})
				.catch((error) => {
					setError(error)
					console.error("Failed loading Stripe client", error)
					track("FailedLoadingStripe", error)
				})
		}, [publishableKey])

		if (error) {
			return <ErrorPage message={errorMessage} title={errorTitle} />
		}

		if (!stripe) {
			return <LoadingPage />
		}

		return <Component stripe={stripe} {...props} />
	}
}

export {withStripe}
