import {useParams} from "@reach/router"
import React from "react"
import {Button, Grid, IconButton, Paper, Typography, useMediaQuery} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import {track} from "@indebted/analytics"
import {AddToCalendarIcon, AppleIcon, CloseIcon, GoogleIcon, OutlookIcon} from "@indebted/assets"
import Modal from "@material-ui/core/Modal"

function getModalStyle() {
	return {
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
		borderRadius: "24px",
		paddingBottom: "20px",
	}
}

export const CalendarModal = ({CalendarURLs, openCalendarModal, headingLabel, buttonLabel}) => {
	const {secureCode} = useParams()
	const [open, setOpen] = React.useState(!!openCalendarModal)
	const mobile = useMediaQuery("(max-width:599px)")
	const useStyles = makeStyles((theme) => {
		return {
			paper: {
				position: "relative",
				width: (mobile && "80%") || "400px",
				backgroundColor: theme.palette.background.paper,
				boxShadow: theme.shadows[5],
				padding: theme.spacing(2, 4, 2),
				outline: "none",
			},
			root: {
				minWidth: 275,
			},
		}
	})
	const classes = useStyles()
	const [modalStyle] = React.useState(getModalStyle)

	const handleOpen = () => {
		track("PPAddToCalendarButtonClicked", {
			SecureCode: secureCode,
		})
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const openInNewTab = (url) => {
		window.open(url, "_blank", "noopener,noreferrer")
	}
	return (
		<>
			<Grid item>
				<Button
					style={{borderRadius: "35px", padding: "6px 16px", marginTop: "10px"}}
					type="button"
					onClick={handleOpen}
					variant="outlined"
					startIcon={<AddToCalendarIcon />}
				>
					{buttonLabel}
				</Button>
			</Grid>
			<Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
				<Paper style={modalStyle} className={classes.paper}>
					<Grid container direction="row" spacing={3} justifyContent="flex-end" alignItems="center">
						<Grid item xs={9} sm={10} style={{paddingLeft: "12px"}}>
							<h2 id="modal-title">{headingLabel}</h2>
						</Grid>
						<Grid item xs={3} sm={2}>
							<IconButton aria-label="close" className={classes.margin} onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
						<Grid item>
							<Button
								style={{borderRadius: "35px", paddingBottom: "10px"}}
								type="button"
								onClick={() => {
									track("PPGoogleCalendarClicked", {
										SecureCode: secureCode,
									})
									openInNewTab(CalendarURLs.google)
								}}
								variant="text"
								startIcon={<GoogleIcon />}
							>
								<Typography
									align="center"
									variant="subtitle1"
									style={{fontSize: "18px", marginLeft: "10px"}}
								>
									Google calendar
								</Typography>
							</Button>
						</Grid>
						<Grid item>
							<Button
								style={{borderRadius: "35px", paddingBottom: "10px"}}
								type="button"
								onClick={() => {
									track("PPAppleCalendarClicked", {
										SecureCode: secureCode,
									})
									openInNewTab(CalendarURLs.apple)
								}}
								variant="text"
								startIcon={<AppleIcon />}
							>
								<Typography
									align="center"
									variant="subtitle1"
									style={{fontSize: "18px", marginLeft: "10px"}}
								>
									Apple calendar
								</Typography>
							</Button>
						</Grid>
						<Grid item>
							<Button
								style={{borderRadius: "35px", paddingBottom: "10px"}}
								type="button"
								onClick={() => {
									track("PPOutlookCalendarClicked", {
										SecureCode: secureCode,
									})
									openInNewTab(CalendarURLs.outlook)
								}}
								variant="text"
								startIcon={<OutlookIcon />}
							>
								<Typography
									align="center"
									variant="subtitle1"
									style={{fontSize: "18px", marginLeft: "10px"}}
								>
									Outook calendar
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Modal>
		</>
	)
}
