import React from "react"
import {Page} from "@indebted/components/Page"
import {Grid, Typography} from "@material-ui/core"
import {Prop, PropLabel, Props, PropValue} from "@indebted/components/Props"
import {PaymentProcessingImage} from "@indebted/assets"
import styled from "styled-components"
import {TextToHTML} from "@indebted/components/TextToHTML"

function PaymentBeingProcessing({ClientName, Amount, I18n}) {
	return (
		<Page>
			<Grid item>
				<Typography align="center" variant="h6">
					{I18n.PaymentSubmitted.Title}
				</Typography>
			</Grid>

			<Grid item>
				<TextToHTML align="center" text={I18n.PaymentSubmitted.Subtitle} />
			</Grid>

			<GridImage item>
				<PaymentProcessingImage width="20em" />
			</GridImage>

			<Props>
				<Prop>
					<PropLabel align="right">{I18n.PaymentSubmitted.PaymentAmountLabel}</PropLabel>
					<PropValue align="left">{Amount}</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{I18n.PaymentSubmitted.PaidToLabel}</PropLabel>
					<PropValue align="left" className="clarity-mask">
						{ClientName}
					</PropValue>
				</Prop>
			</Props>
		</Page>
	)
}

const GridImage = styled(Grid)`
	margin: ${(props) => props.theme.spacing(5)}px;
`

export {PaymentBeingProcessing}
