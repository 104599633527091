import {Page} from "@indebted/components/Page"
import {Grid, Typography} from "@material-ui/core"
import {TextToHTML} from "@indebted/components/TextToHTML"
import React from "react"

import {SelectQuestion} from "./SelectQuestion"
import {MultiQuestion} from "./MultiQuestion"

function Question({question, answers, onAnswer}) {
	const components = {
		select: SelectQuestion,
		"multi-question": MultiQuestion,
	}
	const Component = components[question.Type]
	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" justify="center" spacing={5}>
				<Grid item>
					<Typography variant="h6">{question.Statement}</Typography>
				</Grid>

				<Grid item>
					<TextToHTML variant="body2" text={question.Details} component="div" />
				</Grid>
				<Component question={question} answers={answers} onAnswer={onAnswer} />
			</Grid>
		</Page>
	)
}

export {Question}
