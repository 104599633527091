import React from "react"
import {Button, Grid, Typography} from "@material-ui/core"
import {Page} from "@indebted/components/Page"
import styled from "styled-components"
import {track} from "@indebted/analytics"
import {AcceptedCards} from "@indebted/components/AcceptedCards"
import {useParams} from "@reach/router"

function PaymentMethodSelection({i18n, navigate, paymentMethods}) {
	const {secureCode} = useParams()
	if (paymentMethods.length === 1) {
		navigate(`../${paymentMethods[0].ID}${location.search}`, {replace: true})
		return null
	}

	track("PPMethodPageViewed", {
		SecureCode: secureCode,
	})

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" justify="center" spacing={5}>
				<Grid item>
					<Typography variant="h6">{i18n.Header}</Typography>
				</Grid>
				<Grid item>
					<Typography>{i18n.Title}</Typography>
				</Grid>

				<ButtonsGrid container item direction="column" alignItems="stretch" spacing={5}>
					{paymentMethods.map((method) => (
						<Grid item key={method.ID}>
							<Button
								fullWidth
								variant="outlined"
								onClick={() => {
									track("PPMethodButtonClicked", {
										Method: method.ID,
										SecureCode: secureCode,
									})
									navigate(`../${method.ID}${location.search}`)
								}}
							>
								{method.Display}
							</Button>
							<AcceptedCards list={method.AcceptedCards} />
						</Grid>
					))}
				</ButtonsGrid>
			</Grid>
		</Page>
	)
}

const ButtonsGrid = styled(Grid)`
	margin-top: 20px;
`

export {PaymentMethodSelection}
