import {track} from "@indebted/analytics"
import {navigate} from "@reach/router"

function getWalletType(ev) {
	if (ev.paymentMethod?.card?.wallet?.apple_pay) {
		return "ApplePay"
	} else if (ev.paymentMethod?.card?.wallet?.google_pay) {
		return "GooglePay"
	}
	return null
}

function walletPayAPI({
	walletPayMethods,
	stripe,
	stripeAPI,
	walletPay,
	secureCode,
	clientSecret,
	i18n,
	eventPrefix,
	notification,
	statusURL,
}) {
	if (walletPayMethods?.length === 0) {
		return {init: () => {}, enabledWallet: () => Promise.resolve({applePay: false, googlePay: false})}
	}

	const {paymentRequest, PaymentID} = walletPay({
		stripeAPI,
	})

	function init() {
		paymentRequest.on("paymentmethod", async (ev) => {
			let walletType = getWalletType(ev)
			if (!["debit", "prepaid"].includes(ev.paymentMethod.card.funding)) {
				track(eventPrefix + "FormWalletPayFundingTypeError", {FundingType: ev.paymentMethod.card.funding})
				notification.error(i18n.FormErrorsWalletPayFundingType, 7000)
				ev.complete("fail")
				return
			}
			const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
				clientSecret,
				{payment_method: ev.paymentMethod.id},
				{handleActions: false},
			)

			if (confirmError) {
				handlePaymentRequestError(confirmError, walletType)
				ev.complete("fail")
				return
			}

			ev.complete("success")
			if (paymentIntent.status === "requires_action") {
				const {error} = await stripe.confirmCardPayment(clientSecret)
				if (error) {
					handlePaymentRequestError(error, walletType)
					return
				}
			}

			track(eventPrefix + "FormSucceeded", {SecureCode: secureCode})
			track(eventPrefix + "FormWalletPaySucceeded", {
				SecureCode: secureCode,
				WalletType: walletType,
			})
			navigate(`/${secureCode}/${statusURL}/${PaymentID}`, {
				state: {i18n, PaymentMethod: "DebitCard"},
			})
		})
	}

	function getWalletType(ev) {
		if (ev.paymentMethod?.card?.wallet?.apple_pay) {
			return "ApplePay"
		} else if (ev.paymentMethod?.card?.wallet?.google_pay) {
			return "GooglePay"
		}
		return null
	}

	function handlePaymentRequestError(error, walletType) {
		track(eventPrefix + "FormFailed", {SecureCode: secureCode})
		track(eventPrefix + "FormWalletPayFailed", {
			SecureCode: secureCode,
			Error: error,
			WalletType: walletType,
		})
		notification.error(error.type === "card_error" ? error.message : i18n.FormErrorsWalletPayGeneric, 7000)
	}

	function enabledWallet() {
		if (walletPayMethods?.length === 0) {
			return {applePay: false, googlePay: false}
		}

		return paymentRequest.canMakePayment().then((result) => {
			if (
				(result?.applePay && walletPayMethods.includes("applepay")) ||
				(result?.googlePay && walletPayMethods.includes("googlepay"))
			) {
				track(eventPrefix + "FormWalletPayEnabled", {SecureCode: secureCode})
				return {applePay: result?.applePay, googlePay: result?.googlePay}
			}
			return {applePay: false, googlePay: false}
		})
	}

	return {init, enabledWallet}
}

function initWalletPay({
	walletPayMethods,
	stripe,
	stripeAPI,
	walletPay,
	secureCode,
	clientSecret,
	i18n,
	eventPrefix,
	notification,
	statusURL,
	setPaymentRequest,
}) {
	const handlePaymentRequestError = (error, walletType) => {
		track(eventPrefix + "FormFailed", {SecureCode: secureCode})
		track(eventPrefix + "FormWalletPayFailed", {
			SecureCode: secureCode,
			Error: error,
			WalletType: walletType,
		})
		notification.error(error.type === "card_error" ? error.message : i18n.FormErrorsWalletPayGeneric, 7000)
	}

	if (walletPayMethods?.length > 0) {
		const {paymentRequest, PaymentID} = walletPay({
			stripeAPI,
		})

		paymentRequest.on("paymentmethod", async (ev) => {
			let walletType = getWalletType(ev)
			if (!["debit", "prepaid"].includes(ev.paymentMethod.card.funding)) {
				track(eventPrefix + "FormWalletPayFundingTypeError", {FundingType: ev.paymentMethod.card.funding})
				notification.error(i18n.FormErrorsWalletPayFundingType, 7000)
				ev.complete("fail")
				return
			}
			const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
				clientSecret,
				{payment_method: ev.paymentMethod.id},
				{handleActions: false},
			)

			if (confirmError) {
				handlePaymentRequestError(confirmError, walletType)
				ev.complete("fail")
				return
			}

			ev.complete("success")
			if (paymentIntent.status === "requires_action") {
				const {error} = await stripe.confirmCardPayment(clientSecret)
				if (error) {
					handlePaymentRequestError(error, walletType)
					return
				}
			}

			track(eventPrefix + "FormSucceeded", {SecureCode: secureCode})
			track(eventPrefix + "FormWalletPaySucceeded", {
				SecureCode: secureCode,
				WalletType: walletType,
			})
			navigate(`/${secureCode}/${statusURL}/${PaymentID}`, {
				state: {i18n, PaymentMethod: "DebitCard"},
			})
		})

		paymentRequest.canMakePayment().then((result) => {
			if (
				(result?.applePay && walletPayMethods.includes("applepay")) ||
				(result?.googlePay && walletPayMethods.includes("googlepay"))
			) {
				track(eventPrefix + "FormWalletPayEnabled", {SecureCode: secureCode})
				setPaymentRequest(paymentRequest)
			}
		})
	}
}

export {initWalletPay, walletPayAPI}
