import {Router} from "@reach/router"
import React from "react"
import {setRegionFromAWSRegion} from "@indebted/api"

import {NotificationOfAssignmentAcknowledgement} from "./NotificationOfAssignmentAcknowledgement"

function NotificationOfAssignmentAcknowledgementPage() {
	const queryParams = new URLSearchParams(location.search)
	const awsRegion = queryParams.get("region")
	setRegionFromAWSRegion(awsRegion)

	return (
		<Router>
			<NotificationOfAssignmentAcknowledgement path="/" />
		</Router>
	)
}

export {NotificationOfAssignmentAcknowledgementPage}
