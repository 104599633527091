import React from "react"
import {Router} from "@reach/router"

import {PaymentMethodSelection} from "./PaymentMethodSelection"
import {PartialPaymentForm} from "./PaymentForm"
import {AmountSelectionPage} from "./AmountSelection"

function PartialPaymentPage() {
	return (
		<Router>
			<AmountSelectionPage path="/" />
			<PaymentMethodSelection path="/PaymentMethodSelection" />
			<PartialPaymentForm path="/:paymentMethod" />
		</Router>
	)
}

export {PartialPaymentPage}
