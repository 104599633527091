import React, {useEffect, useState} from "react"
// import styled from "styled-components"
import {Page} from "@indebted/components/Page"
import {AmountInput} from "@indebted/components/AmountInput"
import {Grid, Typography, Button} from "@material-ui/core"
import {track} from "@indebted/analytics"
import {makeStyles} from "@material-ui/core/styles"
import {LoadingPage} from "@indebted/components/Loading"
import {partialPaymentAPI} from "@indebted/api"
import {ErrorPage} from "@indebted/components/Error"
import {navigate} from "@reach/router"

const useStyles = makeStyles(() => ({
	input: {
		background: "rgb(255, 255, 255)",
	},
}))

function AmountSelectionPage({secureCode}) {
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		partialPaymentAPI
			.getAmountPage({secureCode})
			.then((result) => {
				setComponent(<AmountSelection options={result} />)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} />)
			})
	}, [secureCode])

	return Component
}

function AmountSelection({secureCode, options}) {
	const [amount, setAmount] = useState()
	const [remainingBalance, setRemainingBalance] = useState(options.OutstandingBalance)
	const [minimumAmountError, setMinimumAmountError] = useState(false)
	const classes = useStyles()

	useEffect(() => {
		track("PartialPaymentAmountPageViewed", {
			SecureCode: secureCode,
		})
	}, [secureCode])

	useEffect(() => {
		const delayInputTimeoutId = setTimeout(() => {
			setMinimumAmountError(amount * 100 < options.MinimumAmount)
		}, 750)
		return () => clearTimeout(delayInputTimeoutId)
	}, [amount, options.MinimumAmount])

	const handleAmountInput = ({target: {value}}) => {
		setAmount(value)

		if (value === null || value === undefined) {
			setRemainingBalance(options.OutstandingBalance)
		} else if (value * 100 > options.OutstandingBalance) {
			setRemainingBalance(0)
		} else {
			setRemainingBalance(options.OutstandingBalance - value * 100)
		}
	}

	const buttonDisabled = () => {
		return !amount || amount * 100 < options.MinimumAmount
	}

	return (
		<Page padded>
			<Grid container spacing={5} columns={12} justifyContent="center" alignItems="center">
				<Grid item xs={12}>
					<Typography variant="h6">{options.I18n.Header}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography>{options.I18n.Title + options.CustomerBalance}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid
						container
						columns={12}
						spacing={5}
						style={{
							backgroundColor: "#f5f5f8",
							borderRadius: "10px",
							margin: "0px",
							width: "100%",
						}}
						justifyContent="center"
						alignItems="center"
					>
						<Grid item xs={12}>
							<AmountInput
								label={options.I18n.AmountInputLabel}
								error={minimumAmountError}
								value={amount}
								currency={options.Currency}
								helperText={minimumAmountError && options.I18n.MinimumAmountErrorMessage}
								onChange={handleAmountInput}
								required
								autoFocus
								className={classes.input}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography>
								{options.I18n.RemainingBalanceLabel +
									options.Currency +
									" " +
									(remainingBalance / 100).toFixed(2)}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Button
						fullWidth
						disabled={buttonDisabled()}
						onClick={() => {
							track("PartialPaymentAmountButtonClicked", {
								Amount: amount,
								SecureCode: secureCode,
							})
							navigate(`./partial-payment/${options.DefaultPaymentMethod}?amount=${amount}`)
						}}
					>
						{options.I18n.AmountButtonLabel}
					</Button>
				</Grid>
			</Grid>
		</Page>
	)
}

export {AmountSelectionPage}
