import React, {useState} from "react"
import {useLocation, useParams} from "@reach/router"
import {addDays, format} from "date-fns"
import {Box, Button, Grid, Typography} from "@material-ui/core"
import {track} from "@indebted/analytics"
import {Calendar} from "@indebted/components/Calendar"
import {Page} from "@indebted/components/Page"

function CalendarCustomStartDate({i18n, locale, navigate, frequencies}) {
	const search = new URLSearchParams(useLocation().search)
	const frequency = search.get("frequency")
	const maxDate = addDays(
		new Date(),
		frequencies.filter((option) => option.ID === frequency)[0].MaximumDaysUntilStart,
	)
	const minDate = new Date()
	const [selectedDate, setSelectedDate] = useState(minDate)
	const {secureCode} = useParams()

	const handleContinue = () => {
		track("PPDayButtonClicked", {
			SecureCode: secureCode,
		})
		const date = format(selectedDate, "yyyy-MM-dd")
		navigate(`../Amount?${search}&payday=&startdate=${date}`)
	}

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item>
					<Typography variant="h6">{i18n.CustomStartDateHeader}</Typography>
				</Grid>
				<Grid item>
					<Typography>{i18n.CustomStartDateTitle}</Typography>
				</Grid>
			</Grid>

			<Box style={{overflow: "hidden"}}>
				<Calendar
					date={selectedDate}
					onChange={setSelectedDate}
					disablePast={true}
					minDate={minDate}
					maxDate={maxDate}
					locale={locale}
				/>
			</Box>

			<Grid container item direction="column" alignItems="stretch" spacing={5}>
				<Grid item>
					<Button fullWidth variant="outlined" onClick={handleContinue}>
						{i18n.CustomStartDateConfirmation}
					</Button>
				</Grid>
			</Grid>
		</Page>
	)
}

export {CalendarCustomStartDate}
