function Api({stripeSDK, form}) {
	function confirmPayment(clientSecret) {
		return stripeSDK
			.confirmBacsDebitPayment(clientSecret, {
				payment_method: {
					bacs_debit: {
						sort_code: form.SortCode,
						account_number: form.AccountNumber,
					},
					billing_details: {
						name: form.Name,
						email: form.Email,
						address: {
							line1: form.AddressLine1,
							line2: form.AddressLine2,
							city: form.City,
							country: "GB",
							state: "",
							postal_code: form.Postcode,
						},
					},
				},
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
			})
	}

	function confirmSetup(clientSecret) {
		return stripeSDK
			.confirmBacsDebitSetup(clientSecret, {
				payment_method: {
					bacs_debit: {
						sort_code: form.SortCode,
						account_number: form.AccountNumber,
					},
					billing_details: {
						name: form.Name,
						email: form.Email,
						address: {
							line1: form.AddressLine1,
							line2: form.AddressLine2,
							city: form.City,
							country: "GB",
							state: "",
							postal_code: form.Postcode,
						},
					},
				},
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
				return result
			})
	}

	return {confirmPayment, confirmSetup}
}

export {Api}
