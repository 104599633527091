import React, {useState} from "react"
import {
	AuBankAccountElement as StripeAuBankAccountElement,
	useStripe,
	useElements,
	Elements,
} from "@stripe/react-stripe-js"
import {Button, FormControl, FormHelperText, Grid, InputLabel, Link, OutlinedInput, Typography} from "@material-ui/core"
import {useNotification} from "@indebted/components/Notification"
import {TermsOfDiscountOffer} from "@indebted/components/TermsOfDiscountOffer"
import {DebitCardIcon} from "@indebted/assets"

import {Element} from "./Element"
import {Api} from "./api"

function Form({stripe, onSubmit, i18n, termsOfDiscountOffer, locale, paymentMethodSelectionLink}) {
	return (
		<Elements stripe={stripe} options={{locale}}>
			<InnerForm
				onSubmit={onSubmit}
				i18n={i18n}
				termsOfDiscountOffer={termsOfDiscountOffer}
				paymentMethodSelectionLink={paymentMethodSelectionLink}
			/>
		</Elements>
	)
}

function InnerForm({onSubmit, i18n, termsOfDiscountOffer, paymentMethodSelectionLink}) {
	const {notification} = useNotification()
	const [submitting, setSubmitting] = useState(false)
	const [form, setForm] = useState({Email: "", Name: ""})
	const stripe = useStripe()
	const elements = useElements()

	const handleInput =
		(name) =>
		({target: {value}}) => {
			setForm({...form, [name]: value})
		}

	const submit = (event) => {
		event.preventDefault()

		// See https://stripe.com/docs/stripe-js/react#useelements-hook
		if (!stripe || !elements) {
			return
		}

		const directDebitElement = elements.getElement(StripeAuBankAccountElement)

		const emailValidationRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (form.Name.trim() == "" || !form.Email.match(emailValidationRegex)) {
			notification.error(i18n.FormErrorsEmailNameValidation, 5000)
			return
		}

		setSubmitting(true)
		onSubmit({
			stripeAPI: Api({
				stripeSDK: stripe,
				form,
				directDebitElement,
			}),
		}).catch(() => setSubmitting(false))
	}

	return (
		<form onSubmit={submit}>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item container>
					<Grid container direction="column" alignItems="stretch" spacing={5}>
						<Grid item>
							<FormControl variant="outlined" fullWidth={true}>
								<InputLabel htmlFor="fullname">{i18n.FormFullNameLabel}</InputLabel>
								<OutlinedInput
									id="fullname"
									type="text"
									label={i18n.FormFullNameLabel}
									value={form.Name}
									onChange={handleInput("Name")}
									required
									autoFocus
								/>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl variant="outlined" fullWidth={true}>
								<InputLabel htmlFor="email">{i18n.FormEmailLabel}</InputLabel>
								<OutlinedInput
									id="email"
									label={i18n.FormEmailLabel}
									type="email"
									value={form.Email}
									onChange={handleInput("Email")}
									variant="outlined"
									required
								/>
								<FormHelperText>{i18n.FormEmailHelp}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item>
							<Element />
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Typography variant="caption" color="textSecondary" component="p" align="center">
						{i18n.FormTermsAndConditionsText}{" "}
						<Link href={i18n.FormTermsAndConditionsLink} target="_blank" rel="noopener noreferrer">
							{i18n.FormTermsAndConditionsLinkText}
						</Link>
						{termsOfDiscountOffer?.AndText}
						<TermsOfDiscountOffer linkLabel={termsOfDiscountOffer?.ModalLinkLabel} />
					</Typography>
				</Grid>
				<Grid item>
					<Button fullWidth type="submit" disabled={!stripe || submitting}>
						{i18n.FormButtonLabel}
					</Button>
				</Grid>
				<Grid item>
					<Grid container direction="row" alignItems="flex-start" spacing={1}>
						<Grid item xs={1} style={{paddingTop: "2px", paddingRight: "5px", maxWidth: "30px"}}>
							<DebitCardIcon />
						</Grid>
						<Grid item xs={11}>
							<Typography>
								{i18n.FormPaymentMethodSelectionText}
								<Link href={paymentMethodSelectionLink} rel="noopener noreferrer">
									{i18n.FormPaymentMethodSelectionLinkText}
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

export {Form}
