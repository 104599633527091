import styled from "styled-components"
import {Typography, Grid} from "@material-ui/core"

const Props = styled(Grid).attrs({
	container: true,
	direction: "column",
	justify: "center",
	alignItems: "flex-start",
})``

const Prop = styled(Grid).attrs({
	container: true,
	direction: "row",
	justify: "center",
	alignItems: "center",
})`
	* {
		width: 50%;
		padding: ${(props) => props.theme.spacing(1)}px;
	}
`

const PropLabel = styled(Typography)`
	text-align: right;
`

const PropValue = styled(Typography)`
	text-align: left;
`

export {Props, Prop, PropLabel, PropValue}
