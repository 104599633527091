import {getRegionAWSName} from "@indebted/api"
import {Page} from "@indebted/components/Page"
import {Button, Grid, Typography} from "@material-ui/core"
import {track} from "@indebted/analytics"
import {navigate} from "@reach/router"
import React from "react"

function OptOutConfirmation({I18n, channel}) {
	return (
		<Page>
			<Grid item>
				<Typography align="center" variant="h6">
					{I18n.Title}
				</Typography>
			</Grid>

			<Grid item>
				<Button
					fullWidth
					disableElevation
					onClick={() => {
						const urlParams = new URLSearchParams()
						urlParams.set("region", getRegionAWSName())

						track("OptOutButtonClicked")
						navigate(`${channel}/confirm?${urlParams.toString()}`)
					}}
				>
					{I18n.ButtonLabel}
				</Button>
			</Grid>
		</Page>
	)
}

export {OptOutConfirmation}
