import React from "react"
import styled from "styled-components"
import {Grid, Typography} from "@material-ui/core"
import {Page} from "@indebted/components/Page"

import {PaymentOptionLinks} from "./PaymentOptionLinks"

function OutstandingBalance({i18n, PaymentOptions, StatuteOfLimitationsDisclosure}) {
	return (
		<Page>
			<PaddedGrid spacing={5}>
				<Grid item>
					<Typography align="left" className="clarity-mask">
						{i18n.Title}
					</Typography>
				</Grid>

				<Grid item>
					<Typography align="left">{i18n.Subtitle}</Typography>
				</Grid>
			</PaddedGrid>
			<PaymentOptionLinks PaymentOptions={PaymentOptions} />
			{i18n.Footnote && (
				<PaddedGrid spacing={5}>
					<Grid item>
						<Typography variant="body2" color="textSecondary" align="left" className="clarity-mask">
							{i18n.Footnote}
						</Typography>
					</Grid>
				</PaddedGrid>
			)}
			{StatuteOfLimitationsDisclosure && (
				<PaddedGrid spacing={5}>
					<Grid item>
						<Typography variant="body2" color="textSecondary" align="left" style={{whiteSpace: "pre-line"}}>
							{StatuteOfLimitationsDisclosure}
						</Typography>
					</Grid>
				</PaddedGrid>
			)}
		</Page>
	)
}

const PaddedGrid = styled(Grid).attrs({container: true})`
	padding: ${(props) => props.theme.spacing(5)}px;
`

export {OutstandingBalance}
