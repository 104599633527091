import {track} from "@indebted/analytics"
import {navigate} from "@reach/router"

function getWalletType(ev) {
	if (ev.paymentMethod?.card?.wallet?.apple_pay) {
		return "ApplePay"
	} else if (ev.paymentMethod?.card?.wallet?.google_pay) {
		return "GooglePay"
	}
	return null
}

export function initWalletPay({
	walletPayMethods,
	stripe,
	stripeAPI,
	walletPay,
	secureCode,
	clientSecret,
	i18n,
	eventPrefix,
	notification,
	statusURL,
	setPaymentRequest,
}) {
	const handlePaymentRequestError = (error, walletType) => {
		track(eventPrefix + "FormFailed", {SecureCode: secureCode})
		track(eventPrefix + "FormWalletPayFailed", {
			SecureCode: secureCode,
			Error: error,
			WalletType: walletType,
		})
		notification.error(error.type === "card_error" ? error.message : i18n.FormErrorsWalletPayGeneric, 7000)
	}

	if (walletPayMethods?.length > 0) {
		const {paymentRequest, PaymentID} = walletPay({
			stripeAPI,
		})

		paymentRequest.on("paymentmethod", async (ev) => {
			let walletType = getWalletType(ev)
			if (!["debit", "prepaid"].includes(ev.paymentMethod.card.funding)) {
				track(eventPrefix + "FormWalletPayFundingTypeError", {FundingType: ev.paymentMethod.card.funding})
				notification.error(i18n.FormErrorsWalletPayFundingType, 7000)
				ev.complete("fail")
				return
			}
			const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
				clientSecret,
				{payment_method: ev.paymentMethod.id},
				{handleActions: false},
			)

			if (confirmError) {
				handlePaymentRequestError(confirmError, walletType)
				ev.complete("fail")
			} else {
				ev.complete("success")
				if (paymentIntent.status === "requires_action") {
					const {error} = await stripe.confirmCardPayment(clientSecret)
					if (error) {
						handlePaymentRequestError(error, walletType)
					} else {
						track(eventPrefix + "FormSucceeded", {SecureCode: secureCode})
						track(eventPrefix + "FormWalletPaySucceeded", {
							SecureCode: secureCode,
							WalletType: walletType,
						})
						navigate(`/${secureCode}/${statusURL}/${PaymentID}`, {
							state: {i18n, PaymentMethod: "DebitCard"},
						})
					}
				} else {
					track(eventPrefix + "FormSucceeded", {SecureCode: secureCode})
					track(eventPrefix + "FormWalletPaySucceeded", {
						SecureCode: secureCode,
						WalletType: walletType,
					})
					navigate(`/${secureCode}/${statusURL}/${PaymentID}`, {
						state: {i18n, PaymentMethod: "DebitCard"},
					})
				}
			}
		})

		paymentRequest.canMakePayment().then((result) => {
			if (
				(result?.applePay && walletPayMethods.includes("applepay")) ||
				(result?.googlePay && walletPayMethods.includes("googlepay"))
			) {
				track(eventPrefix + "FormWalletPayEnabled", {SecureCode: secureCode})
				setPaymentRequest(paymentRequest)
			}
		})
	}
}
