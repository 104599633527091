import React from "react"
import {CrossIconImage} from "@indebted/assets"
import {Page} from "@indebted/components/Page"
import {Grid} from "@material-ui/core"
import {TextToHTML} from "@indebted/components/TextToHTML"

function NotificationOfAssignmentAcknowledgementFailure({Message}) {
	return (
		<Page>
			<Grid item xs>
				<Grid container spacing={0} direction="column">
					<Grid item xs style={{display: "flex", justifyContent: "center"}}>
						<CrossIconImage />
					</Grid>
					<Grid container spacing={0} direction="column">
						<Grid item xs style={{display: "flex", justifyContent: "center"}}>
							<h1>Request Failed</h1>
						</Grid>
						<Grid item xs style={{display: "flex", justifyContent: "center"}}>
							<TextToHTML
								paragraph
								align="center"
								text="Unfortunately, it looks like we were unable to action your request."
							/>
						</Grid>
					</Grid>
					<Grid item xs style={{display: "flex", justifyContent: "center", paddingBottom: "35px"}}>
						<TextToHTML paragraph gutterBottom text={Message} />
					</Grid>
					<Grid item xs>
						<TextToHTML
							paragraph
							gutterBottom
							text={`Our apologies, but something went wrong. Please click the 'Request payment link' button in the original email to try again. If this issue persists please <a href="https://www.indebted.co/support">contact</a> our customer support team.`}
						/>
					</Grid>
					<Grid item xs>
						<TextToHTML
							variant="body2"
							text={`This communication is from a debt collector. This is an attempt to collect a debt and any information obtained will be used for that purpose. Please read below for important disclosures.
							Unless you notify us within 30 days after receiving this notice that you dispute the validity of this debt or any portion thereof, we will assume this debt is valid. If you notify us in writing within 30 days from receiving this notice that you dispute all or any portion of this debt, we will obtain verification of the debt or obtain a copy of a judgment and send you a copy of such verification or judgment. You may also request in writing within 30 days of receiving this notice that we provide you with the name and address of the original creditor, if different from the original creditor.
							You can view your Privacy Rights and State Disclosures by visiting these URLs: https://www.indebted.co/en-us/policies/privacy-policy, https://www.indebted.co/en-us/policies/state-disclosures.
							Should you have any questions or would like further information regarding your outstanding balance please email customersupport-us@indebted.co or call us at +1 855 559 0310.
							Our operating hours are Monday – Friday: 9 am ET to 6 pm ET.`}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Page>
	)
}

export {NotificationOfAssignmentAcknowledgementFailure}
