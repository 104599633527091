import {createTheme} from "@material-ui/core/styles"
import createSpacing from "@material-ui/core/styles/createSpacing"

const typography = require("./typography")

const themeName = "InDebted"
const spacing = createSpacing(4)

// Use this tool for named colors, e.g. vulcanSlate
// http://chir.ag/projects/name-that-color/
const colors = {
	// accents
	indebtedPrimary: "#474CDE",
	slateNavy: "#2F384A",
	waterLeaf: "#A0E9E3",
	stratos: "#020452",
	stratosLight: "#4D4F85",
	stratosLight2: "#626392",

	// slate
	midnightSlate: "#12161F",
	darkSlate: "#636A77",
	highlightedSlate: "#1C232F",

	// reds
	warningRed: "#EF0909",
	warningButtonRed: "#D7153A",
	errorRed: "#E70808",
	blushRed: "#FEDADA",

	// greens
	discountGreen: "#368700",
	onlineGreen: "#2BA829",
	foamGreen: "#C4F4C3",

	// greyscale
	black: "#000000",
	dustyGrey: "#979797",
	goneGrey: "#F3F3F4",
	white: "#FFFFFF",
}

const palette = {
	primary: {main: colors.indebtedPrimary, contrastText: colors.white},
	secondary: {main: colors.slateNavy, contrastText: colors.white},
}

const theme = createTheme({
	themeName,
	spacing,
	palette,
	typography,
	colors,
	props: {
		MuiButton: {
			color: "primary",
			variant: "contained",
		},
		MuiIconButton: {
			color: "primary",
		},
		MuiListItem: {
			dense: true,
		},
		MuiAppBar: {
			elevation: 1,
		},
	},
	overrides: {
		MuiContainer: {
			root: {
				padding: spacing(3),
			},
		},
		MuiTableCell: {
			head: {
				color: colors.grey,
				fontSize: "0.875rem",
			},
		},
		MuiButton: {
			root: {
				textTransform: "none",
			},
		},
	},
})

export {theme, colors}
