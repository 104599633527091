import React, {useState} from "react"
import {CardElement as StripeCardElement} from "@stripe/react-stripe-js"
import styled, {css} from "styled-components"
import {Box} from "@material-ui/core"
import {grey} from "@material-ui/core/colors"

const options = (hidePostalCode) => ({
	style: {
		base: {
			fontFamily: "Roboto, Helvetica, Arial, sans-serif",
			fontSize: "16px",
			color: grey[700],
		},
	},
	hidePostalCode,
})

const Element = ({hidePostalCode}) => {
	const [focused, setFocused] = useState(false)

	return (
		<CardElementWrapper focused={focused ? 1 : 0}>
			<StripeCardElement
				options={options(hidePostalCode)}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
			/>
		</CardElementWrapper>
	)
}

const CardElementWrapper = styled(Box)`
	border-radius: 4px;
	border-style: solid;
	transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	${(props) => (props.focused ? focused : nonFocused)};
`

const focused = css`
	padding: 15px;
	border-width: 2px;
	border-color: ${(props) => props.theme.colors.indebtedPrimary};
`

const nonFocused = css`
	padding: 16px;
	border-width: 1px;
	border-color: ${grey[400]};
	&:hover {
		border-color: ${grey[900]};
	}
`

export {Element}
