import {Button, Grid} from "@material-ui/core"
import React from "react"

import {MultiInput} from "./MultiInput"

function Actions({question, answers, subQuestion, onAnswer, form}) {
	return (
		<Grid container direction="column" style={{marginTop: "20px"}}>
			<Grid item xs>
				<Grid container direction="row" spacing={5} justify="space-evenly">
					{subQuestion.Options.map((input, index) => {
						return (
							<Grid item xs={6} key={index}>
								<Button
									fullWidth
									variant="outlined"
									onClick={() => {
										const formData = new FormData(form.current)
										const answeredData = {
											QuestionNumber: question.Number,
											OptionNumber: input.Number,
											[question.Name]: question.SubQuestions.filter(
												(otherQuestion) => otherQuestion.Type !== subQuestion.Type,
											).map((otherQuestion) =>
												otherQuestion.Inputs.reduce(
													(previous, input) => ({
														...previous,
														[input.Name]: formData.get(
															`${input.Name}-${question.Number}-${otherQuestion.Number}`,
														),
													}),
													{QuestionNumber: otherQuestion.Number},
												),
											),
										}
										onAnswer(input, [...answers, answeredData])
									}}
								>
									{input.Label}
								</Button>
							</Grid>
						)
					})}
				</Grid>
			</Grid>
		</Grid>
	)
}

function SubQuestion({question, answers, onAnswer, subQuestion, form}) {
	const components = {
		"multi-input": MultiInput,
		select: Actions,
	}

	const Component = components[subQuestion.Type]

	return <Component question={question} answers={answers} subQuestion={subQuestion} onAnswer={onAnswer} form={form} />
}

export {SubQuestion}
