import React from "react"
import {Typography, Grid} from "@material-ui/core"
import {Page} from "@indebted/components/Page"
import {TextToHTML} from "@indebted/components/TextToHTML"

function AccountsClosedDueToSOL({i18n}) {
	return (
		<Page>
			<Grid item>
				<Typography align="center" variant="h6">
					{i18n.Title}
				</Typography>
			</Grid>

			<Grid item>
				<TextToHTML align="center" text={i18n.Message} className="clarity-mask" />
			</Grid>
		</Page>
	)
}

export {AccountsClosedDueToSOL}
