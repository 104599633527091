import React from "react"
import styled from "styled-components"
import {Grid} from "@material-ui/core"

const Page = styled(({theme, padded, ...props}) => <Grid {...props} />).attrs({
	container: true,
	direction: "column",
	alignItems: "center",
	spacing: 5,
})`
	padding-top: ${(props) => props.theme.spacing(5)}px;
	padding-bottom: ${(props) => props.theme.spacing(5)}px;

	${(props) =>
		props.padded &&
		`
		padding-left: ${props.theme.spacing(5)}px;
		padding-right: ${props.theme.spacing(5)}px;
	`}
`

export {Page}
