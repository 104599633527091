import React from "react"
import {Page} from "@indebted/components/Page"
import {Grid, Typography, Button} from "@material-ui/core"
import {track} from "@indebted/analytics"
import {useParams} from "@reach/router"
import {format} from "date-fns"
import {CalendarCheckIcon} from "@indebted/assets"

function FortnightlyPayDay({i18n, frequency, navigate, todayStartDateEnabled}) {
	const params = new URLSearchParams(location.search)
	const {secureCode} = useParams()
	track("PPDayPageViewed", {
		SecureCode: secureCode,
	})

	const options = frequency.Options.reduce((list, option) => {
		if (list.indexOf(option.Weekday) == -1) {
			list.push(option.Weekday)
		}

		return list
	}, [])

	function WeekdayButton({weekday}) {
		return (
			<Grid item>
				<Button
					fullWidth
					variant="outlined"
					onClick={() => {
						track("PPDayButtonClicked", {
							SecureCode: secureCode,
						})
						navigate(`../FortnightlyNextPayDate?${params}&frequency=Fortnightly&weekday=${weekday}`)
					}}
				>
					{weekday}
				</Button>
			</Grid>
		)
	}

	function PayDayOptions() {
		return (
			<>
				<Grid item>
					<Typography variant="h6">{i18n.DateSelection.DateSelectionHeader}</Typography>
				</Grid>
				<Grid item>
					<Typography>{i18n.DateSelection.PayDateTitle}</Typography>
				</Grid>

				<Grid container item direction="column" alignItems="stretch" spacing={5}>
					{options.map((weekday) => (
						<WeekdayButton key={weekday} weekday={weekday} />
					))}
				</Grid>

				<Grid container item direction="column" alignItems="stretch" spacing={5}>
					<Grid item>
						<Typography variant="h6">{i18n.DateSelection.CustomStartDateHeader}</Typography>
					</Grid>
					<Grid item>
						<Typography>{i18n.DateSelection.CustomStartDateDescription}</Typography>
					</Grid>
				</Grid>
				<Grid container item direction="column" alignItems="stretch" spacing={5}>
					<Grid item>
						<Button
							fullWidth
							variant="outlined"
							onClick={() => {
								track("PPDayButtonClicked", {
									SecureCode: secureCode,
								})
								navigate(`../CustomStartDate?${params}&frequency=${frequency.ID}`)
							}}
						>
							{frequency.CustomStartDate.Display}
						</Button>
					</Grid>
				</Grid>
			</>
		)
	}

	function PayTodayButton({buttonLabel}) {
		return (
			<Grid item>
				<Button
					fullWidth
					variant="outlined"
					onClick={() => {
						track("PPPayTodayButtonClicked", {
							SecureCode: secureCode,
						})
						const date = format(new Date(), "yyyy-MM-dd")
						navigate(`../Amount?${params}&frequency=${frequency.ID}&payday=&startdate=${date}`)
					}}
				>
					{buttonLabel}
				</Button>
			</Grid>
		)
	}

	function TodayStartDate() {
		return (
			<>
				<Grid item>
					<Typography variant="h6">{i18n.PayToday.Header}</Typography>
				</Grid>
				<Grid item>
					<Typography>{i18n.PayToday.TitleFortnightly}</Typography>
				</Grid>

				<Grid container item direction="column" alignItems="stretch" spacing={5}>
					<PayTodayButton buttonLabel={i18n.PayToday.ButtonLabel} />
				</Grid>

				<Grid container item direction="column" alignItems="stretch" spacing={5}>
					<Grid item>
						<Button
							fullWidth
							variant="outlined"
							startIcon={<CalendarCheckIcon />}
							onClick={() => {
								track("PPDayButtonClicked", {
									SecureCode: secureCode,
								})
								navigate(`../CustomStartDate?${params}&frequency=${frequency.ID}`)
							}}
						>
							{i18n.PayToday.CustomStartDateButtonLabel}
						</Button>
					</Grid>
				</Grid>
			</>
		)
	}

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" justify="center" spacing={5}>
				{todayStartDateEnabled ? <TodayStartDate /> : <PayDayOptions />}
			</Grid>
		</Page>
	)
}

export {FortnightlyPayDay}
