import React, {useState} from "react"
import {
	Button,
	FormControl,
	FormControlLabel,
	Checkbox,
	Grid,
	InputLabel,
	Link,
	OutlinedInput,
	Typography,
} from "@material-ui/core"
import styled from "styled-components"
import {useNotification} from "@indebted/components/Notification"
import {DiscountMessage} from "@indebted/components/DiscountMessage"
import {TextToHTML} from "@indebted/components/TextToHTML"
import {customerAPI} from "@indebted/api"
import {useParams} from "@reach/router"
import {DebitCardIcon} from "@indebted/assets"

const FormContent = ({
	onSubmit,
	i18n,
	discountMessage,
	name,
	email,
	paymentMethodSelectionLink,
	requestESIGNConsent,
	esignConsent,
}) => {
	const [form, setForm] = useState({
		Name: name,
		Email: email,
		ESIGNConsent: false,
	})
	const {secureCode} = useParams()
	const {notification} = useNotification()

	const [showESIGNErrorMessage, setShowESIGNErrorMessage] = useState(false)
	const [esignConsentEnabled, setESIGNConsentEnabled] = useState(true)

	function handleInput(name) {
		return ({target: {value}}) => {
			setForm({...form, [name]: value})
		}
	}

	// eslint-disable-next-line complexity
	async function submit(e) {
		e.preventDefault()

		if (requestESIGNConsent && !form.ESIGNConsent) {
			setShowESIGNErrorMessage(true)
			return
		}

		try {
			if (requestESIGNConsent && esignConsentEnabled) {
				const customer = await customerAPI.find({secureCode})
				await customerAPI.grantESIGNConsent({customerID: customer.ID})
				setESIGNConsentEnabled(false)
			}
			onSubmit(form)
		} catch (error) {
			notification.error(error.message || error.Message, 5000)
		}
	}

	const onChangeESIGNConsentCheckbox = ({target: {checked}}) => {
		setForm({...form, ["ESIGNConsent"]: checked})
		setShowESIGNErrorMessage(!checked)
	}

	return (
		<form onSubmit={submit}>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item>
					<DiscountMessage discountMessage={discountMessage} />
				</Grid>
				<Grid item>
					<Typography variant="h6">{i18n.Header}</Typography>
				</Grid>
				<Grid item>
					<TextToHTML text={i18n.Title} />
				</Grid>
				<Grid item>
					<FormControl variant="outlined" fullWidth={true}>
						<InputLabel htmlFor="accountHolderName">{i18n.FormFullNameLabel}</InputLabel>
						<OutlinedInput
							id="accountHolderName"
							type="text"
							label={i18n.FormFullNameLabel}
							value={form.Name}
							onChange={handleInput("Name")}
							required
							autoFocus
						/>
					</FormControl>
				</Grid>
				<Grid item>
					<FormControl variant="outlined" fullWidth={true}>
						<InputLabel htmlFor="accountHolderEmail">{i18n.FormEmailLabel}</InputLabel>
						<OutlinedInput
							id="accountHolderEmail"
							type="email"
							label={i18n.FormEmailLabel}
							value={form.Email}
							onChange={handleInput("Email")}
							required
						/>
					</FormControl>
				</Grid>
				{requestESIGNConsent && (
					<Grid item>
						<Grid container direction="column" alignItems="stretch" spacing={5}>
							<Grid item>
								<StyledFormControlLabel
									control={
										<StyledCheckbox
											disabled={!esignConsentEnabled}
											onChange={onChangeESIGNConsentCheckbox}
											name="esign-consent"
											color="primary"
											error={showESIGNErrorMessage}
										/>
									}
									label={
										<Typography variant="p" color="textPrimary" component="p">
											{esignConsent.CheckboxText} {esignConsent.SeeOurTermsAndConditionsText}
											<Link
												href={esignConsent.TermsAndConditionsURL}
												rel="noopener noreferrer"
												target="_blank"
											>
												{esignConsent.TermsAndConditionsLabel}
											</Link>
										</Typography>
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				)}
				{showESIGNErrorMessage && (
					<Grid item>
						<StyledAlertGrid item>
							<TextToHTML text={esignConsent.ErrorMessage} />
						</StyledAlertGrid>
					</Grid>
				)}
				<Grid item>
					<Button fullWidth type="submit">
						{i18n.FormButtonLabel}
					</Button>
				</Grid>
				<Grid item>
					<Grid container direction="row" alignItems="flex-start" spacing={1}>
						<Grid item xs={1} style={{paddingTop: "2px", paddingRight: "5px", maxWidth: "30px"}}>
							<DebitCardIcon />
						</Grid>
						<Grid item xs={11}>
							<Typography>
								{i18n.FormPaymentMethodSelectionText}
								<Link href={paymentMethodSelectionLink} rel="noopener noreferrer">
									{i18n.FormPaymentMethodSelectionLinkText}
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const StyledFormControlLabel = styled(FormControlLabel)`
	align-items: start;
`

const StyledCheckbox = styled(Checkbox)`
	padding-top: 0;

	svg {
		color: ${(props) => (props.error ? "#D7153A" : "inherit")};
	}
`
const StyledAlertGrid = styled(Grid)`
	border: solid 1px #d7153a;
	border-radius: 5px;
	margin-bottom: 10px;
	padding: 10px;

	p {
		font-size: 0.75rem;
		color: #d7153a;
	}
`

export {FormContent}
