import React from "react"
import {Router} from "@reach/router"

import {PaymentMethodSelection} from "./PaymentMethodSelection"
import {PaymentForm} from "./PaymentForm"
import {DefaultPaymentMethodPage} from "./DefaultPaymentMethodPage"

function PaymentInFullPage() {
	return (
		<Router>
			<DefaultPaymentMethodPage path="/" />
			<PaymentMethodSelection path="/PaymentMethodSelection" />
			<PaymentForm path="/:paymentMethod" />
		</Router>
	)
}

export {PaymentInFullPage}
