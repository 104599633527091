function Api({stripeSDK}) {
	function confirmPayment(clientSecret) {
		return stripeSDK.confirmUsBankAccountPayment(clientSecret).then((result) => {
			if (result.error) return Promise.reject(result.error)

			if (result.paymentIntent.status === "requires_payment_method") {
				return Promise.reject({message: "Payment confirmation has failed! Please, try again."})
			}

			if (result.paymentIntent.next_action?.type === "verify_with_microdeposits") {
				// this will only happen if we enable microdeposits. As of the date of this comment, microdeposits is disabled. However,
				// we will keep this check to avoid confusion when comparing our integration with Stripe's documentation.

				return Promise.reject({message: "Payment confirmation has failed! Please, try again."})
			}

			return Promise.resolve()
		})
	}

	function confirmSetup(clientSecret) {
		return stripeSDK.confirmUsBankAccountSetup(clientSecret).then((result) => {
			const {setupIntent, error} = result

			if (error) {
				return Promise.reject(error)
			}

			if (setupIntent.status === "requires_payment_method") {
				return Promise.reject({message: "Payment confirmation has failed! Please, try again."})
			}

			if (setupIntent.status === "succeeded") {
				return result
			}

			if (setupIntent.next_action?.type === "verify_with_microdeposits") {
				return Promise.reject({message: "Payment confirmation has failed! Please, try again."})
			}

			return result
		})
	}

	return {confirmPayment, confirmSetup}
}

export {Api}
