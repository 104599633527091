import {Grid} from "@material-ui/core"
import React, {useRef} from "react"

import {SubQuestion} from "./SubQuestion"

function MultiQuestion({question, answers, onAnswer}) {
	const form = useRef(null)

	return (
		<Grid item>
			<form ref={form}>
				{question.SubQuestions.map((subQuestion) => {
					return (
						<SubQuestion
							key={question.Number + "_" + subQuestion.Number}
							question={question}
							subQuestion={subQuestion}
							onAnswer={onAnswer}
							answers={answers}
							form={form}
						/>
					)
				})}
			</form>
		</Grid>
	)
}

export {MultiQuestion}
