import React from "react"
import {Page} from "@indebted/components/Page"
import {Grid} from "@material-ui/core"
import {StripeUSBankAccountForm} from "@indebted/stripe"

function USDirectDebitForm({
	i18n,
	discountMessage,
	locale,
	publishableKey,
	clientSecret,
	onSubmit,
	requestESIGNConsent,
	esignConsent,
}) {
	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item>
					<StripeUSBankAccountForm
						i18n={i18n}
						discountMessage={discountMessage}
						locale={locale}
						publishableKey={publishableKey}
						clientSecret={clientSecret}
						onSubmit={onSubmit}
						onCollect={(stripe, stripeObj) =>
							stripe.collectBankAccountForSetup(stripeObj).then((result) => {
								return {intent: result.setupIntent, error: result.error}
							})
						}
						paymentMethodSelectionLink={`PaymentMethodSelection${location.search}`}
						requestESIGNConsent={requestESIGNConsent}
						esignConsent={esignConsent}
					/>
				</Grid>
			</Grid>
		</Page>
	)
}

export {USDirectDebitForm}
