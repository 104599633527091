import React from "react"
import {Page} from "@indebted/components/Page"
import {Grid, Typography} from "@material-ui/core"
import {StripeAuBankAccountForm, StripeAuBankAccountDisclaimer} from "@indebted/stripe"
import {DiscountMessage} from "@indebted/components/DiscountMessage"
import {track} from "@indebted/analytics"
import {useParams} from "@reach/router"

function AuDirectDebitForm({i18n, termsOfDiscountOffer, discountMessage, locale, onSubmit, publishableKey}) {
	const {secureCode} = useParams()
	if (discountMessage) {
		if (discountMessage.Eligible) {
			track("PaymentPlanSettlementOfferEligibleMessageViewed", {
				SecureCode: secureCode,
			})
		} else {
			track("PaymentPlanSettlementOfferNotEligibleMessageViewed", {
				SecureCode: secureCode,
			})
		}
	}

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item>
					<DiscountMessage discountMessage={discountMessage} />
				</Grid>
				<Grid item>
					<Typography variant="h6">{i18n.Header}</Typography>
				</Grid>
				<Grid item>
					<Typography>{i18n.Title}</Typography>
				</Grid>

				<Grid item>
					<StripeAuBankAccountForm
						i18n={i18n}
						termsOfDiscountOffer={termsOfDiscountOffer}
						locale={locale}
						onSubmit={onSubmit}
						publishableKey={publishableKey}
						paymentMethodSelectionLink={`PaymentMethodSelection${location.search}`}
					/>
				</Grid>

				<Grid item>
					<StripeAuBankAccountDisclaimer />
				</Grid>
			</Grid>
		</Page>
	)
}

export {AuDirectDebitForm}
