import React from "react"
import styled from "styled-components"
import {Container, AppBar, Toolbar} from "@material-ui/core"
import {colors, theme} from "@indebted/theme"

import {InDebted} from "./InDebted"

function Layout({children}) {
	return (
		<>
			<StyledAppBar position="fixed">
				<CenteredToolbar>
					<InDebted variant="inline" width="10em" />
				</CenteredToolbar>
			</StyledAppBar>

			{/* Second toolbar here is a hack necessary to make contents display below, not under, the appbar */}
			{/* https://material-ui.com/components/app-bar/#fixed-placement */}
			<Toolbar />

			<PageContainer component="main" maxWidth="sm">
				{children}
			</PageContainer>
		</>
	)
}

const CenteredToolbar = styled(Toolbar)`
	justify-content: center;
`

const PageContainer = styled(Container).attrs({className: "indebted-page-container"})`
	background-color: ${colors.white};
	padding-bottom: ${theme.spacing(20)}px;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	border-left: 1px solid rgba(0, 0, 0, 0.12);
	min-height: 100vh;
`

const StyledAppBar = styled(AppBar)`
	background-color: ${colors.white};
`

export {Layout}
