import {Button, Card, CardHeader, Grid} from "@material-ui/core"
import {GreenCheckIconV2, ReoccurringDollarIcon} from "@indebted/assets"
import {colors} from "@indebted/theme"
import React from "react"
import {navigate, useParams} from "@reach/router"

import {
	CardContentLessPadding,
	CardHeadingTypography,
	ColoredTypography,
	ColoredTypographyWithBottomMargin,
	GridWithBottomMargin,
	StyledDiv,
} from "./styles"

const PaymentSuccessfulPage = ({I18n, amount}) => {
	const {paymentPlanID} = useParams()
	return (
		<Grid container direction="column" alignItems="center" justifyContent="center">
			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{marginTop: "25px"}}
			>
				<GreenCheckIconV2 />
			</Grid>
			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{marginBottom: "10px"}}
			>
				<ColoredTypographyWithBottomMargin
					variant="h5"
					color={colors.stratosLight2}
					align="center"
					style={{padding: "0 64px", fontWeight: 700, lineHeight: "28px"}}
				>
					{I18n.PaymentSucceeded.Title}
				</ColoredTypographyWithBottomMargin>
			</Grid>
			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{marginBottom: "34px"}}
			>
				<ColoredTypographyWithBottomMargin
					variant="subtitle1"
					color={colors.stratos}
					align="center"
					style={{padding: "0 64px", lineHeight: "22px"}}
				>
					{I18n.PaymentSucceeded.Subtitle}
				</ColoredTypographyWithBottomMargin>
			</Grid>

			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				style={{padding: "10px 20px", marginBottom: "35px"}}
			>
				<Card
					style={{
						width: "100%",
						marginBottom: "20px",
						boxShadow: "0px 11px 22px 0px rgba(25,89,135,0.2)",
						background: "#fafafa",
					}}
				>
					<CardHeader
						title={
							<CardHeadingTypography color={colors.stratos}>
								{I18n.PaymentSucceeded.PaymentDetailsCardTitle}
							</CardHeadingTypography>
						}
					/>
					<CardContentLessPadding>
						<GridWithBottomMargin container item direction="row" justifyContent="space-between">
							<StyledDiv>
								<ColoredTypography variant="subtitle1" color={colors.stratosLight2}>
									{I18n.PaymentSucceeded.PaymentDetailsCardPaymentAmountLabel}
								</ColoredTypography>
							</StyledDiv>
							<StyledDiv>
								<ColoredTypography variant="subtitle1" color={colors.stratosLight2}>
									{amount}
								</ColoredTypography>
							</StyledDiv>
						</GridWithBottomMargin>
					</CardContentLessPadding>
				</Card>
			</Grid>

			<Button
				style={{borderRadius: "35px", padding: "6px 30px", marginTop: "10px"}}
				type="button"
				onClick={() => {
					navigate(`./${paymentPlanID}`)
					navigate(0)
				}}
				variant="contained"
				startIcon={<ReoccurringDollarIcon />}
			>
				{I18n.PaymentSucceeded.ButtonText}
			</Button>
		</Grid>
	)
}

export {PaymentSuccessfulPage}
