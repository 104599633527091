import {Button, Grid} from "@material-ui/core"
import React from "react"
import styled from "styled-components"

function SelectQuestion({question, answers, onAnswer}) {
	return (
		<ButtonsGrid container item direction="column" alignItems="stretch" spacing={5}>
			{question.Options.map((option) => (
				<Grid key={option.Label} item>
					<Button
						fullWidth
						variant="outlined"
						onClick={() =>
							onAnswer(option, [
								...answers,
								{QuestionNumber: question.Number, OptionNumber: option.Number},
							])
						}
					>
						{option.Label}
					</Button>
				</Grid>
			))}
		</ButtonsGrid>
	)
}

const ButtonsGrid = styled(Grid)`
	margin-top: 20px;
`

export {SelectQuestion}
