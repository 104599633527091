import React, {useEffect} from "react"
import {LoadingPage} from "@indebted/components/Loading"

function Accepted({secureCode, ID, PaymentOptionType, navigate}) {
	useEffect(() => {
		if (PaymentOptionType === "payment-plan") {
			navigate(`/${secureCode}/${PaymentOptionType}?aaid=${ID}`)
		} else {
			navigate(`/${secureCode}/${PaymentOptionType}/PaymentMethodSelection?aaid=${ID}`)
		}
	})

	return <LoadingPage />
}

export {Accepted}
