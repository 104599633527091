import React, {useEffect, useState} from "react"
import {LoadingPage} from "@indebted/components/Loading"
import {notificationOfAssignmentAcknowledgementAPI} from "@indebted/api"

import {NotificationOfAssignmentAcknowledgementFailure} from "./NotificationOfAssignmentAcknowledgementFailure"

function NotificationOfAssignmentAcknowledgement() {
	const [Component, setComponent] = useState(<LoadingPage />)

	const queryParams = new URLSearchParams(location.search)
	const ids = queryParams.get("ids")
	const customerId = queryParams.get("customer-id")

	useEffect(() => {
		notificationOfAssignmentAcknowledgementAPI
			.acknowledge({ids, customerId})
			.then(({RedirectURL}) => {
				const timeToCatchup = 1000
				setTimeout(() => (window.location = RedirectURL), timeToCatchup)
			})
			.catch((error) => {
				setComponent(<NotificationOfAssignmentAcknowledgementFailure {...error} />)
			})
	}, [ids, customerId])

	return Component
}

export {NotificationOfAssignmentAcknowledgement}
