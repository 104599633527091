import React from "react"
import {Router} from "@reach/router"
import {setRegionFromSecureCode} from "@indebted/api"
import {NotFoundPage} from "@indebted/components/Error"

import {SecureCodeLandingPage} from "./SecureCodeLandingPage"
import {PaymentInFullPage} from "./PaymentInFullPage"
import {PartialPaymentPage} from "./PartialPaymentPage"
import {PartialPaymentStatusPage} from "./PartialPaymentStatusPage"
import {PaymentStatusPage} from "./PaymentInFullStatusPage"
import {PaymentPlanPage} from "./PaymentPlanPage"
import {PaymentPlanStatusWithSecureCodePage} from "./PaymentPlanStatusPage"
import {AffordabilityAssessmentPage} from "./AffordabilityAssessmentPage"

function Pages({secureCode}) {
	setRegionFromSecureCode(secureCode)

	return (
		<Router>
			<SecureCodeLandingPage path="/" />
			<PaymentInFullPage path="/payment-in-full/*" />
			<PartialPaymentPage path="/partial-payment/*" />
			<PaymentStatusPage path="/payment-in-full-status/:paymentID" />
			<PartialPaymentStatusPage path="/partial-payment-status/:paymentID" />
			<PaymentPlanPage path="/payment-plan/*" />
			<PaymentPlanStatusWithSecureCodePage path="/payment-plan-status/:paymentPlanID" />
			<AffordabilityAssessmentPage path="/affordability-assessment/*" />
			<NotFoundPage default />
		</Router>
	)
}

export {Pages}
