import React from "react"
import mastercardLogo from "jsx:@indebted/assets/mastercard.svg"
import visaLogo from "jsx:@indebted/assets/visa.svg"
import notEligible from "jsx:@indebted/assets/red-exclamation-icon.svg"
import eligible from "jsx:@indebted/assets/green-check-icon.svg"
import exclamationMark from "jsx:@indebted/assets/exclamation-mark.svg"
import closeIcon from "jsx:@indebted/assets/close-icon.svg"
import crossIconImage from "jsx:@indebted/assets/cross-icon.svg"
import thickIconImage from "jsx:@indebted/assets/thick-icon.svg"
import invalidCodeImage from "jsx:@indebted/assets/invalid-code.svg"
import paymentProcessingImage from "jsx:@indebted/assets/payment-processing.svg"
import paymentCompletedImage from "jsx:@indebted/assets/payment-completed.svg"
import amountIcon from "jsx:@indebted/assets/amount-icon.svg"
import frequencyIcon from "jsx:@indebted/assets/frequency-icon.svg"
import startDateCalendarIcon from "jsx:@indebted/assets/start-date-calendar-icon.svg"
import endDateCalendarIcon from "jsx:@indebted/assets/end-date-calendar-icon.svg"
import setupSucceededLogo from "jsx:@indebted/assets/setup-succeeded-logo.svg"
import addToCalendarIcon from "jsx:@indebted/assets/add-to-calendar.svg"
import googleIcon from "jsx:@indebted/assets/google-icon.svg"
import appleIcon from "jsx:@indebted/assets/apple-icon.svg"
import outlookIcon from "jsx:@indebted/assets/outlook-icon.svg"
import yellowExclamationIcon from "jsx:@indebted/assets/yellow-exclamation-icon.svg"
import clockIcon from "jsx:@indebted/assets/clock-icon.svg"
import greenCheckIconV2 from "jsx:@indebted/assets/green-check-icon-v2.svg"
import redCrossIcon from "jsx:@indebted/assets/red-cross-icon.svg"
import reoccuringDollarIcon from "jsx:@indebted/assets/reoccuring-dollar.svg"
import errorIcon from "jsx:@indebted/assets/error-icon.svg"
import watchIcon from "jsx:@indebted/assets/watch-icon.svg"
import wallet from "jsx:@indebted/assets/wallet.svg"
import BankAccount from "jsx:@indebted/assets/bank-account.svg"
import DebitCard from "jsx:@indebted/assets/debit-card.svg"
import calendarCheckIcon from "jsx:@indebted/assets/calendar-check-icon.svg"

function MastercardLogo() {
	return <img src={mastercardLogo} />
}

function VisaLogo() {
	return <img src={visaLogo} />
}

function Eligible() {
	return <img src={eligible} />
}

function NotEligible() {
	return <img src={notEligible} />
}

function ExclamationMark() {
	return <img src={exclamationMark} />
}

function CloseIcon() {
	return <img src={closeIcon} />
}

function CrossIconImage() {
	return <img src={crossIconImage} />
}

function ThickIconImage() {
	return <img src={thickIconImage} />
}

function InvalidCodeImage() {
	return <img src={invalidCodeImage} />
}

function PaymentProcessingImage() {
	return <img src={paymentProcessingImage} />
}

function PaymentCompletedImage() {
	return <img src={paymentCompletedImage} />
}

function AmountIcon() {
	return <img src={amountIcon} />
}

function FrequencyIcon() {
	return <img src={frequencyIcon} />
}

function StartDateCalendarIcon() {
	return <img src={startDateCalendarIcon} />
}

function EndDateCalendarIcon() {
	return <img src={endDateCalendarIcon} />
}

function SetupSucceededLogo() {
	return <img src={setupSucceededLogo} />
}

function AddToCalendarIcon() {
	return <img src={addToCalendarIcon} />
}

function GoogleIcon() {
	return <img src={googleIcon} />
}

function AppleIcon() {
	return <img src={appleIcon} />
}

function OutlookIcon() {
	return <img src={outlookIcon} />
}

function YellowExclamationIcon() {
	return <img src={yellowExclamationIcon} />
}

function ClockIcon() {
	return <img src={clockIcon} />
}

function GreenCheckIconV2() {
	return <img src={greenCheckIconV2} />
}

function RedCrossIcon() {
	return <img src={redCrossIcon} />
}

function ReoccurringDollarIcon() {
	return <img src={reoccuringDollarIcon} />
}

function ErrorIcon() {
	return <img src={errorIcon} />
}

function WatchIcon() {
	return <img src={watchIcon} />
}

function WalletIcon() {
	return <img src={wallet} width="100%" />
}

function BankAccountIcon() {
	return <img src={BankAccount} />
}

function DebitCardIcon() {
	return <img src={DebitCard} />
}

function CalendarCheckIcon() {
	return <img src={calendarCheckIcon} />
}

export {
	MastercardLogo,
	VisaLogo,
	NotEligible,
	Eligible,
	ExclamationMark,
	CloseIcon,
	CrossIconImage,
	ThickIconImage,
	InvalidCodeImage,
	PaymentProcessingImage,
	PaymentCompletedImage,
	AmountIcon,
	FrequencyIcon,
	StartDateCalendarIcon,
	EndDateCalendarIcon,
	SetupSucceededLogo,
	AddToCalendarIcon,
	GoogleIcon,
	AppleIcon,
	OutlookIcon,
	YellowExclamationIcon,
	ClockIcon,
	GreenCheckIconV2,
	RedCrossIcon,
	ReoccurringDollarIcon,
	ErrorIcon,
	WatchIcon,
	WalletIcon,
	BankAccountIcon,
	DebitCardIcon,
	CalendarCheckIcon,
}
