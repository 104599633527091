import React from "react"
import styled from "styled-components"
import {Page} from "@indebted/components/Page"
import {Grid, Typography, Button} from "@material-ui/core"
import {track} from "@indebted/analytics"
import {useParams} from "@reach/router"

function FortnightlyNextPayDate({i18n, frequency, navigate}) {
	const params = new URLSearchParams(location.search)
	const weekday = params.get("weekday")
	const options = frequency.Options.filter((option) => option.Weekday == weekday)
	const {secureCode} = useParams()
	track("PPFortnightlyDatePageViewed", {
		SecureCode: secureCode,
	})
	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" justify="center" spacing={5}>
				<Grid item>
					<Typography variant="h6">{i18n.DateSelectionHeader}</Typography>
				</Grid>
				<Grid item>
					<Typography>{i18n.NextPayDateTitle}</Typography>
				</Grid>

				<ButtonsGrid container item direction="column" alignItems="stretch" spacing={5}>
					{options.map((option) => (
						<Grid key={option.ID} item>
							<Button
								fullWidth
								variant="outlined"
								onClick={() => {
									track("PPFortnightlyDateButtonClicked", {
										SecureCode: secureCode,
									})
									navigate(`../Amount?${params}&payday=${option.ID}`)
								}}
							>
								{option.Weekday} {option.Date}
							</Button>
						</Grid>
					))}
				</ButtonsGrid>
			</Grid>
		</Page>
	)
}

const ButtonsGrid = styled(Grid)`
	margin-top: 20px;
`

export {FortnightlyNextPayDate}
