import React from "react"
import {Typography, Button, Grid} from "@material-ui/core"
import {navigate, useParams} from "@reach/router"
import {track} from "@indebted/analytics"

function PaymentPlanOptionLink({Title, Subtitle, ButtonLabel, RequiresAffordabilityAssessment}) {
	const {secureCode} = useParams()

	return (
		<Grid container direction="column" alignItems="stretch" spacing={4}>
			<Grid item>
				<Typography variant="h6" align="left">
					{Title}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body2" color="textSecondary" align="left">
					{Subtitle}
				</Typography>
			</Grid>
			<Grid item>
				<Button
					fullWidth
					disableElevation
					onClick={() => {
						track("PPButtonClicked", {SecureCode: secureCode})
						if (RequiresAffordabilityAssessment) {
							navigate(`/${secureCode}/affordability-assessment/payment-plan/initiate`)
						} else {
							navigate(`/${secureCode}/payment-plan`)
						}
					}}
				>
					{ButtonLabel}
				</Button>
			</Grid>
		</Grid>
	)
}

export {PaymentPlanOptionLink}
