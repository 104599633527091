import React from "react"
import {Page} from "@indebted/components/Page"
import {Grid, Typography} from "@material-ui/core"
import {TextToHTML} from "@indebted/components/TextToHTML"

function OptOutAlreadyCompleted({I18n}) {
	return (
		<Page>
			<Grid item>
				<Typography align="center" variant="h6">
					{I18n.Title}
				</Typography>
			</Grid>

			<Grid item>
				<TextToHTML align="center" text={I18n.Subtitle} />
			</Grid>
		</Page>
	)
}
export {OptOutAlreadyCompleted}
