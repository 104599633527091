import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {useParams} from "@reach/router"
import {Typography, Dialog, DialogTitle, DialogContent, Link, Grid, Box, IconButton} from "@material-ui/core"
import {paymentPlanAPI} from "@indebted/api"
import {track} from "@indebted/analytics"
import {CloseIcon} from "@indebted/assets"

import {LoadingIcon} from "./Loading"
import {ErrorPage} from "./Error"

function TermsOfDiscountOffer({linkLabel}) {
	const {secureCode} = useParams()
	const [Component, setComponent] = useState(<LoadingIcon />)
	const [open, setOpen] = React.useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		paymentPlanAPI
			.getTermsOfDiscountOffer({secureCode})
			.then((res) => {
				setComponent(
					TermsOfDiscountOfferBody({
						secureCode: secureCode,
						title: res.Title,
						sections: res.Sections,
						termsAndConditionsLabel: res.TermsAndConditionsLabel,
						termsAndConditionsURL: res.TermsAndConditionsURL,
						handleClose: handleClose,
					}),
				)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} />)
			})
	}, [secureCode])

	return (
		<>
			<StyledLink onClick={handleClickOpen}>{linkLabel}</StyledLink>
			<Dialog open={open} onClose={handleClose} scroll="paper" aria-labelledby="offers-terms-title" maxWidth="xs">
				{Component}
			</Dialog>
		</>
	)
}

function TermsOfDiscountOfferBody({
	secureCode,
	title,
	sections,
	termsAndConditionsLabel,
	termsAndConditionsURL,
	handleClose,
}) {
	track("PaymentPlanOfferTermsViewed", {
		SecureCode: secureCode,
	})
	return (
		<StyledBody>
			<DialogTitle id="offers-terms-title">
				<StyledBox>
					{title}
					<IconButton
						onClick={handleClose}
						style={{
							padding: "0px",
						}}
					>
						<CloseIcon />
					</IconButton>
				</StyledBox>
			</DialogTitle>
			<DialogContent>
				{Array.isArray(sections) &&
					sections.map((section) => {
						return (
							<div key={section.Header}>
								<Typography variant="subtitle1">{section.Header}</Typography>
								{Array.isArray(section.Bulletpoints) && (
									<>
										<ul>
											{section.Bulletpoints.map((point) => {
												return <li key={point}>{point}</li>
											})}
											<Link href={termsAndConditionsURL} target="_blank">
												{termsAndConditionsLabel}
											</Link>
										</ul>
										{section.MissedPaymentDisclaimer}
									</>
								)}
								{Array.isArray(section.Paragraphs) &&
									section.Paragraphs.map((paragraph, index) => {
										return (
											<Typography variant="body2" component="p" paragraph key={index}>
												{paragraph}
											</Typography>
										)
									})}
							</div>
						)
					})}
			</DialogContent>
		</StyledBody>
	)
}

const StyledBox = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: end;
	svg {
		cursor: pointer;
	}
`

const StyledBody = styled(Grid)`
	overflow-x: hidden;

	div#offers-terms-title {
		padding-bottom: 0px;
	}

	h6 {
		margin-top: 5px;
		font-weight: 500;
	}

	ul {
		margin-top: 0px;
		margin-bottom: 16px;
		list-style: none;
		padding-left: 22px;
	}

	ul li:nth-child(odd) {
		margin-bottom: 10px;
		margin-top: 5px;
	}

	ul li:before {
		padding: 8px;
		margin-left: -30px;
		content: "✓";
	}
`

const StyledLink = styled(Link)`
	cursor: pointer;
`

export {TermsOfDiscountOffer}
