import React from "react"
import {render} from "react-dom"
import {Router, Redirect} from "@reach/router"
import {InDebtedCoRoute} from "@indebted/components/InDebtedCoRoute"
import {NotFoundPage} from "@indebted/components/Error"
import {Regions} from "@indebted/api"
import {ThemeProvider} from "styled-components"
import {theme} from "@indebted/theme"
import {Layout} from "@indebted/components/Layout"
import {CssBaseline, MuiThemeProvider} from "@material-ui/core"
import {NotificationProvider} from "@indebted/components/Notification"

import {Pages} from "./Pages"
import {OptOutPage} from "./OptOutPage"
import {TransactionalEmailOptOutPage} from "./TransactionalEmailOptOutPage"
import {NotificationOfAssignmentAcknowledgementPage} from "./NotificationOfAssignmentAcknowledgementPage"
import {PaymentPlanStatusWithRegionPage} from "./Pages/PaymentPlanStatusPage"
import {GrantESIGNConsentPage} from "./GrantESIGNConsentPage"

render(
	<MuiThemeProvider theme={theme}>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<NotificationProvider>
				<Layout>
					<Router>
						<InDebtedCoRoute path="/" />

						<OptOutPage path="/opt-out/:token/:channel/*" />
						<TransactionalEmailOptOutPage path="/transactional-email-opt-out/:token" />
						<NotificationOfAssignmentAcknowledgementPage path="/accounts/ack-noa" />
						<GrantESIGNConsentPage path="customers/grant-esign-consent/*" />
						<GrantESIGNConsentPage path="customers/:regionPrefix/grant-esign-consent/*" />
						<PaymentPlanStatusWithRegionPage path="/0/payment-plan/:paymentPlanID" region={0} />
						<PaymentPlanStatusWithRegionPage path="/1/payment-plan/:paymentPlanID" region={1} />
						<Pages path="/:secureCode/*" />
						<Redirect
							from="/payment-plan/:paymentPlanID"
							to={`/${Regions.AU.number()}/payment-plan/:paymentPlanID`}
							noThrow
						/>

						<NotFoundPage default />
					</Router>
				</Layout>
			</NotificationProvider>
		</ThemeProvider>
	</MuiThemeProvider>,
	document.getElementById("root"),
)
