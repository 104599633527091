import React from "react"
import {Page} from "@indebted/components/Page"
import {Grid, Typography} from "@material-ui/core"
import {Link, useParams} from "@reach/router"

function PaymentFailed({I18n}) {
	const {secureCode} = useParams()

	return (
		<Page>
			<Grid item>
				<Typography align="center" variant="h6">
					{I18n.PaymentFailed.Title}
				</Typography>
			</Grid>

			<Grid item>
				<Typography align="center">
					<Link to={`/${secureCode}`}>{I18n.PaymentFailed.RetryLinkText}</Link>
				</Typography>
			</Grid>
		</Page>
	)
}
export {PaymentFailed}
