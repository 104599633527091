function Api({form, directDebitElement, stripeSDK}) {
	function confirmPayment(clientSecret) {
		return stripeSDK
			.confirmAuBecsDebitPayment(clientSecret, {
				payment_method: {
					au_becs_debit: directDebitElement,
					billing_details: {
						name: form.Name,
						email: form.Email,
					},
				},
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
			})
	}

	function confirmSetup(clientSecret) {
		return stripeSDK
			.confirmAuBecsDebitSetup(clientSecret, {
				payment_method: {
					au_becs_debit: directDebitElement,
					billing_details: {
						name: form.Name,
						email: form.Email,
					},
				},
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
				return result
			})
	}

	return {confirmPayment, confirmSetup}
}

export {Api}
