import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {Typography, Button, Grid, Link, Box} from "@material-ui/core"
import {navigate, useParams} from "@reach/router"
import {track} from "@indebted/analytics"
import {paymentAPI} from "@indebted/api"

function SettlementInFullOptionLink({
	BadgeLabel,
	BadgeDiscount,
	Title,
	BulletPoints,
	ButtonLabel,
	OutstandingBalanceLabel,
	OutstandingBalanceValue,
	DiscountTermsAndConditionsLabel,
	DiscountTermsAndConditionsURL,
	RequiresAffordabilityAssessment,
	OutstandingBalanceWithDiscountLabel,
	OutstandingBalanceWithDiscountValue,
}) {
	const {secureCode} = useParams()
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null)
	useEffect(() => {
		paymentAPI.getDefaultPaymentMethod({secureCode}).then((result) => {
			setDefaultPaymentMethod(result.DefaultPaymentMethod)
		})
	}, [secureCode])

	const redirect = (RequiresAffordabilityAssessment) => {
		if (RequiresAffordabilityAssessment) {
			navigate(`/${secureCode}/affordability-assessment/payment-in-full/initiate`)
		} else {
			navigate(`/${secureCode}/payment-in-full/${defaultPaymentMethod}`)
		}
	}

	return (
		<>
			<DiscountBanner>
				{BadgeDiscount.split(" ").map((item) => (
					<div key={item}>{item}</div>
				))}
			</DiscountBanner>
			<GreyGrid container direction="column" alignItems="stretch" spacing={4}>
				<BadgeGrid item>{BadgeLabel}</BadgeGrid>
				<TitleGrid item className="title">
					<Typography variant="h6" align="left">
						{Title}
					</Typography>
				</TitleGrid>
				<ListGrid item>
					<ul>
						{BulletPoints.map((bulletpoint, i) => (
							<li key={bulletpoint}>
								{bulletpoint}
								{i === 0 ? (
									<Link
										href={DiscountTermsAndConditionsURL}
										target="_blank"
										rel="noreferrer noopener"
									>
										{" "}
										{DiscountTermsAndConditionsLabel}
									</Link>
								) : (
									""
								)}
							</li>
						))}
					</ul>
				</ListGrid>
				<OfferBalanceGrid item>
					<Box>
						<Typography variant="subtitle1">{OutstandingBalanceWithDiscountValue}</Typography>&nbsp;
						<Typography variant="body2" color="textSecondary">
							{OutstandingBalanceWithDiscountLabel}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" color="textSecondary">
							{OutstandingBalanceLabel}
						</Typography>
						&nbsp;
						<Typography component="span" variant="body2" color="textSecondary">
							{OutstandingBalanceValue}
						</Typography>
					</Box>
				</OfferBalanceGrid>
				<Grid item>
					<Button
						fullWidth
						disableElevation
						onClick={() => {
							track("PIFButtonClicked", {SecureCode: secureCode})
							redirect(RequiresAffordabilityAssessment)
						}}
						style={{marginBottom: "8px"}}
					>
						{ButtonLabel}
					</Button>
				</Grid>
			</GreyGrid>
		</>
	)
}

const OfferBalanceGrid = styled(Grid)`
	div {
		display: flex;
		align-items: baseline;
		h6 {
			font-weight: 600;
		}
		span {
			text-decoration: line-through;
		}
	}
`

const ListGrid = styled(Grid)`
	ul {
		list-style: none;
		padding-left: 22px;
	}

	ul li:nth-child(odd) {
		margin-bottom: 10px;
	}

	ul li:before {
		padding: 8px;
		margin-left: -30px;
		content: "✓";
	}
`

const BadgeGrid = styled(Grid)`
	text-transform: uppercase;
	margin-top: 10px;
	padding-bottom: 0px !important;
	font-size: 12px;
	font-weight: 500;
`

const GreyGrid = styled(Grid)`
	width: calc(100% + 56px);
	margin: -14px -28px;
	padding: 0 20px;
	background-color: #f5f5f8;

	div.title {
		padding-top: 0px;
		padding-right: 100px;
	}
`

const TitleGrid = styled(Grid)`
	@media (max-width: 600px) {
		margin-bottom: 20px;
	}
`

const DiscountBanner = styled(Box)`
	background-color: rgb(145, 229, 221);
	position: absolute;
	top: 0;
	right: 27px;
	padding: 5px;
	font-size: 14px;
	font-weight: 800;
	border: 2px solid rgb(145, 229, 221);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	width: 90px;
	color: #010041;
	text-align: center;
	text-transform: uppercase;
	div:nth-child(2) {
		font-size: 26px;
	}
`

export {SettlementInFullOptionLink}
