import {Router} from "@reach/router"
import {NotFoundPage} from "@indebted/components/Error"
import React from "react"

import {Initiate} from "./Initiate"
import {Questions} from "./Questions"
import {Result} from "./Result"

function AffordabilityAssessmentPage() {
	return (
		<Router>
			<Initiate path="/:paymentOptionType/initiate" />
			<Questions path="/:id" />
			<Result path="/:id/result" />
			<NotFoundPage default />
		</Router>
	)
}

export {AffordabilityAssessmentPage}
