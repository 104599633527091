function Api({form, debitCardElement, stripeSDK, fundingTypeErrorMessage}) {
	function confirmSetup(clientSecret) {
		return stripeSDK
			.createPaymentMethod({
				type: "card",
				card: debitCardElement,
				billing_details: {
					name: form.Name,
					email: form.Email,
				},
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
				return validateFundingType(result)
			})
			.then((result) => {
				return Promise.all([
					result,
					stripeSDK
						.confirmCardSetup(clientSecret, {
							payment_method: result.paymentMethod.id,
						})
						.then((result) => {
							if (result.error) return Promise.reject(result.error)
							return result
						}),
				])
			})
	}

	function createPaymentMethod() {
		return stripeSDK
			.createPaymentMethod({
				type: "card",
				card: debitCardElement,
				billing_details: {
					name: form.Name,
					email: form.Email,
				},
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
				return validateFundingType(result)
			})
	}

	function createPaymentRequest(PaymentAmount, PaymentCurrency, PaymentCountry, Label) {
		return stripeSDK.paymentRequest({
			country: PaymentCountry,
			currency: PaymentCurrency.toLowerCase(),
			total: {
				label: Label,
				amount: PaymentAmount,
			},
			requestPayerName: true,
			requestPayerEmail: true,
		})
	}

	function confirmPayment(clientSecret) {
		return stripeSDK
			.createPaymentMethod({
				type: "card",
				card: debitCardElement,
				billing_details: {
					name: form.Name,
					email: form.Email,
				},
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
				return validateFundingType(result)
			})
			.then((result) => {
				return stripeSDK.confirmCardPayment(clientSecret, {
					payment_method: result.paymentMethod.id,
				})
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
			})
	}

	function validateFundingType(result) {
		if (!["debit", "prepaid"].includes(result.paymentMethod.card.funding)) {
			return Promise.reject({message: fundingTypeErrorMessage})
		}

		return Promise.resolve(result)
	}

	return {createPaymentMethod, confirmSetup, confirmPayment, createPaymentRequest}
}

export {Api}
