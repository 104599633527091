import React from "react"
import {Grid, Typography} from "@material-ui/core"
import {ExclamationMark} from "@indebted/assets"

import {Page} from "./Page"

function PaymentConfirmationTimeout({I18n}) {
	return (
		<Page>
			<Grid item xs style={{display: "flex", justifyContent: "center"}}>
				<ExclamationMark />
			</Grid>
			<Grid item>
				<Typography align="center" variant="h6">
					{I18n.PaymentConfirmationTimeoutMessage.Title}
				</Typography>
			</Grid>

			<Grid item>
				<Typography align="center" display="block">
					{I18n.PaymentConfirmationTimeoutMessage.Subtitle}
				</Typography>
			</Grid>
		</Page>
	)
}

export {PaymentConfirmationTimeout}
