import React from "react"
import {colors} from "@indebted/theme"

const variants = {
	inline: Inline,
	square: Square,
}

function InDebted({variant, width = "100%", brandColor = colors.waterLeaf, textColor = colors.stratos}) {
	const Variant = variants[variant]
	return <Variant width={width} brandColor={brandColor} textColor={textColor} />
}

function Inline({width, brandColor, textColor}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 862.49 160" fill="none">
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<path fill={brandColor} d="M0,80C0,13.6,13.6,0,80,0s80,13.6,80,80-13.6,80-80,80S0,146.4,0,80" />
					<path
						fill={textColor}
						d="M85.16,130l-31.72-.1A2.51,2.51,0,0,1,51,127.39l0-7.2a2.51,2.51,0,0,1,2.51-2.49l31.68.1a48.93,48.93,0,0,0,19.55-5c12.08-6.19,18.21-17.2,18.21-32.74s-6.13-26.55-18.21-32.74a49,49,0,0,0-19.56-5l-43.9-.1a2.5,2.5,0,0,1-2.49-2.51v-7.2A2.51,2.51,0,0,1,41.28,30l43.9.1a60.75,60.75,0,0,1,24.64,6.13c11.54,5.77,25.29,18.06,25.29,43.82s-13.75,38.06-25.29,43.82A60.75,60.75,0,0,1,85.16,130Z"
					/>
					<rect fill={textColor} x="28.89" y="51.89" width="50.82" height="12.2" rx="2.5" />
					<rect fill={textColor} x="33.97" y="73.84" width="50.82" height="12.2" rx="2.5" />
					<rect fill={textColor} x="39.05" y="95.79" width="50.82" height="12.2" rx="2.5" />
					<path
						fill={textColor}
						d="M206.48,28.73V136.56a2,2,0,0,1-2,2H186.05a2,2,0,0,1-2-2V28.73a2,2,0,0,1,2-2h18.43A2,2,0,0,1,206.48,28.73Z"
					/>
					<path
						fill={textColor}
						d="M291.41,58.53q9.76,10,9.77,28v50.07a2,2,0,0,1-2,2H280.75a2,2,0,0,1-2-2v-47q0-10.57-5.29-16.26T259,67.58q-9.29,0-14.66,5.69T239,89.53v47a2,2,0,0,1-2,2H218.59a2,2,0,0,1-2-2V51.8a2,2,0,0,1,2-2H237a2,2,0,0,1,2,2v6.34a1,1,0,0,0,1.74.67,31.29,31.29,0,0,1,9.71-7,35.45,35.45,0,0,1,15.3-3.28Q281.64,48.52,291.41,58.53Z"
					/>
					<path
						fill={textColor}
						d="M383.58,33.62a49.19,49.19,0,0,1,20.67,19.62Q411.54,66,411.54,82.8t-7.29,29.4a49.47,49.47,0,0,1-20.67,19.47q-13.38,6.89-31,6.89H315.49a2,2,0,0,1-2-2V28.73a2,2,0,0,1,2-2h37.09Q370.21,26.73,383.58,33.62ZM379,109.88q9.6-9.61,9.61-27.08T379,55.49q-9.61-9.85-27.24-9.86H337.92a2,2,0,0,0-2,2v69.86a2,2,0,0,0,2,2h13.86Q369.41,119.49,379,109.88Z"
					/>
					<path
						fill={textColor}
						d="M506.37,100.91H443.45a1,1,0,0,0-1,1.1q1,8.83,6.62,14a20.75,20.75,0,0,0,14.58,5.45q12.18,0,17.51-10.2a1,1,0,0,1,.88-.54h22.22a1,1,0,0,1,.95,1.31,40.38,40.38,0,0,1-14.32,19.76Q480,140,464.13,140a46.22,46.22,0,0,1-23-5.69,40,40,0,0,1-15.86-16.1,49.34,49.34,0,0,1-5.69-24q0-13.79,5.61-24.19a39.15,39.15,0,0,1,15.7-16,50.39,50.39,0,0,1,45.9-.16,38.24,38.24,0,0,1,15.54,15.46,46.69,46.69,0,0,1,5.53,23,54.72,54.72,0,0,1-.51,7.8A1,1,0,0,1,506.37,100.91ZM483.6,85.85a1,1,0,0,0,1-1A17.58,17.58,0,0,0,478.39,72a22.14,22.14,0,0,0-14.9-5.21,20.34,20.34,0,0,0-14,5,21.73,21.73,0,0,0-6.78,12.86,1,1,0,0,0,1,1.16Z"
					/>
					<path
						fill={textColor}
						d="M760.37,100.91H697.45a1,1,0,0,0-1,1.1q1,8.83,6.62,14a20.75,20.75,0,0,0,14.58,5.45q12.18,0,17.51-10.2a1,1,0,0,1,.88-.54h22.22a1,1,0,0,1,1,1.31,40.38,40.38,0,0,1-14.32,19.76Q734,140,718.13,140a46.22,46.22,0,0,1-23-5.69,40,40,0,0,1-15.86-16.1,49.34,49.34,0,0,1-5.69-24q0-13.79,5.61-24.19a39.15,39.15,0,0,1,15.7-16,50.39,50.39,0,0,1,45.9-.16,38.24,38.24,0,0,1,15.54,15.46,46.69,46.69,0,0,1,5.53,23,54.72,54.72,0,0,1-.51,7.8A1,1,0,0,1,760.37,100.91ZM737.6,85.85a1,1,0,0,0,1-1A17.58,17.58,0,0,0,732.39,72a22.14,22.14,0,0,0-14.9-5.21,20.34,20.34,0,0,0-14,5,21.73,21.73,0,0,0-6.78,12.86,1,1,0,0,0,1,1.16Z"
					/>
					<path
						fill={textColor}
						d="M550.58,52.36a36.73,36.73,0,0,1,17.38-4A38.84,38.84,0,0,1,588.55,54a39.48,39.48,0,0,1,14.58,15.94q5.36,10.34,5.36,24A52.29,52.29,0,0,1,603.13,118a40.33,40.33,0,0,1-14.58,16.26A38,38,0,0,1,568,140a36.82,36.82,0,0,1-17.38-3.92,34.79,34.79,0,0,1-10.18-7.94,1,1,0,0,0-1.76.64v7.78a2,2,0,0,1-2,2H518.21a2,2,0,0,1-2-2V22a2,2,0,0,1,2-2h18.43a2,2,0,0,1,2,2V59.79a1,1,0,0,0,1.76.64A33.08,33.08,0,0,1,550.58,52.36ZM582.3,80a22.78,22.78,0,0,0-8.65-8.89,23.16,23.16,0,0,0-11.62-3,22.39,22.39,0,0,0-11.45,3.13,23.19,23.19,0,0,0-8.66,9,28.31,28.31,0,0,0-3.28,13.94,28.31,28.31,0,0,0,3.28,13.94,23.19,23.19,0,0,0,8.66,9,22.48,22.48,0,0,0,13,3.08,22.09,22.09,0,0,0,7.33-1.75A23.48,23.48,0,0,0,582.3,108a28.64,28.64,0,0,0,3.28-14.1A27.86,27.86,0,0,0,582.3,80Z"
					/>
					<path
						fill={textColor}
						d="M838.31,60.43a1,1,0,0,0,1.75-.64V22a2,2,0,0,1,2-2h18.43a2,2,0,0,1,2,2V136.56a2,2,0,0,1-2,2H842.06a2,2,0,0,1-2-2v-7.78a1,1,0,0,0-1.76-.64,34.75,34.75,0,0,1-10.17,7.94A36.86,36.86,0,0,1,810.74,140a37.94,37.94,0,0,1-20.58-5.77A40.33,40.33,0,0,1,775.58,118a52.17,52.17,0,0,1-5.37-24.11q0-13.62,5.37-24A39.48,39.48,0,0,1,790.16,54a38.78,38.78,0,0,1,20.58-5.61,36.77,36.77,0,0,1,17.39,4A33.32,33.32,0,0,1,838.31,60.43ZM793.12,93.86a28.65,28.65,0,0,0,3.29,14.1,23.44,23.44,0,0,0,11.41,10.54,22,22,0,0,0,7.32,1.75,22.51,22.51,0,0,0,13-3.08,23.16,23.16,0,0,0,8.65-9,28.31,28.31,0,0,0,3.28-13.94,28.31,28.31,0,0,0-3.28-13.94,23.16,23.16,0,0,0-8.65-9,22.45,22.45,0,0,0-11.46-3.13,23.15,23.15,0,0,0-11.61,3A22.72,22.72,0,0,0,796.41,80,27.86,27.86,0,0,0,793.12,93.86Z"
					/>
					<path
						fill={textColor}
						d="M647,68.22a1,1,0,0,0-1,1v41.94c0,3,.72,5.16,2.16,6.49s3.87,2,7.29,2h8.42a2,2,0,0,1,2,2v14.91a2,2,0,0,1-2,2h-12.1q-28.37,0-28.36-27.56V69.22a1,1,0,0,0-1-1h-7.57a2,2,0,0,1-2-2V51.8a2,2,0,0,1,2-2h7.57a1,1,0,0,0,1-1V29.85a2,2,0,0,1,2-2H644a2,2,0,0,1,2,2V48.8a1,1,0,0,0,1,1h16.87a2,2,0,0,1,2,2V66.22a2,2,0,0,1-2,2Z"
					/>
				</g>
			</g>
		</svg>
	)
}

function Square({width, brandColor, textColor}) {
	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160" fill="none">
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<path fill={brandColor} d="M0,80C0,13.6,13.6,0,80,0s80,13.6,80,80-13.6,80-80,80S0,146.4,0,80" />
					<path
						fill={textColor}
						d="M85.16,130l-31.72-.1A2.51,2.51,0,0,1,51,127.39l0-7.2a2.51,2.51,0,0,1,2.51-2.49l31.68.1a48.93,48.93,0,0,0,19.55-5c12.08-6.19,18.21-17.2,18.21-32.74s-6.13-26.55-18.21-32.74a49,49,0,0,0-19.56-5l-43.9-.1a2.5,2.5,0,0,1-2.49-2.51v-7.2A2.51,2.51,0,0,1,41.28,30l43.9.1a60.75,60.75,0,0,1,24.64,6.13c11.54,5.77,25.29,18.06,25.29,43.82s-13.75,38.06-25.29,43.82A60.75,60.75,0,0,1,85.16,130Z"
					/>
					<rect fill={textColor} x="28.89" y="51.89" width="50.82" height="12.2" rx="2.5" />
					<rect fill={textColor} x="33.97" y="73.84" width="50.82" height="12.2" rx="2.5" />
					<rect fill={textColor} x="39.05" y="95.79" width="50.82" height="12.2" rx="2.5" />
				</g>
			</g>
		</svg>
	)
}

export {InDebted}
