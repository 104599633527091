import React from "react"
import styled from "styled-components"
import {Typography, Grid} from "@material-ui/core"
import {Page} from "@indebted/components/Page"
import {PaymentCompletedImage} from "@indebted/assets"
import {Props, Prop, PropValue, PropLabel} from "@indebted/components/Props"
import {TextToHTML} from "@indebted/components/TextToHTML"

function PaymentPlanCompleted({
	PaymentPlanInstalmentAmount,
	PaymentPlanFrequency,
	PaymentPlanStartDate,
	PaymentPlanEndDate,
	ClientName,
	I18n,
}) {
	return (
		<Page>
			<Grid item>
				<Typography align="center" variant="h6">
					{I18n.Completed.Title}
				</Typography>
			</Grid>

			<Grid item>
				<TextToHTML text={I18n.Completed.Message} align="center" className="clarity-mask" />
			</Grid>

			<GridImage item>
				<PaymentCompletedImage width="20em" />
			</GridImage>

			<Props>
				<Prop>
					<PropLabel align="right">{I18n.Completed.PlanLabel}</PropLabel>
					<PropValue align="left">
						{PaymentPlanInstalmentAmount} {PaymentPlanFrequency}
					</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{I18n.Completed.PaidToLabel}</PropLabel>
					<PropValue align="left" className="clarity-mask">
						{ClientName}
					</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{I18n.Completed.StartDateLabel}</PropLabel>
					<PropValue align="left">{PaymentPlanStartDate}</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{I18n.Completed.EndDateLabel}</PropLabel>
					<PropValue align="left">{PaymentPlanEndDate}</PropValue>
				</Prop>
			</Props>
		</Page>
	)
}

const GridImage = styled(Grid)`
	margin: ${(props) => props.theme.spacing(5)}px;
`

export {PaymentPlanCompleted}
