import {Button, Grid, Typography} from "@material-ui/core"
import {TextToHTML} from "@indebted/components/TextToHTML"
import React, {useState} from "react"
import {useNotification} from "@indebted/components/Notification"

function EFTAConfirmationDialog({
	modal,
	onCancel,
	onSubmitEFTA,
	i18n,
	secureCode,
	stripe,
	debitCardElement,
	form,
	providerData,
	paymentPlanID,
	paymentMethod,
	last4CardDigits,
}) {
	const {notification} = useNotification()
	const [disabled, setDisabled] = useState(false)
	const [disableBackdropClick, setDisableBackdropClick] = useState(false)
	const [disableEscapeKeyDown, setDisableEscapeKeyDown] = useState(false)

	const submitButtonClicked = (event) => {
		event.preventDefault()

		setDisabled(true)
		setDisableBackdropClick(true)
		setDisableEscapeKeyDown(true)

		return onSubmitEFTA(
			{
				secureCode,
				stripe,
				debitCardElement,
				form,
				providerData,
				paymentPlanID,
				notification,
				i18n,
				paymentMethod,
			}, // TODO: rename to stripePaymentMethod
		).catch(() => {
			setDisabled(false)
			setDisableBackdropClick(false)
			setDisableEscapeKeyDown(false)

			onCancel()
			modal.Close()
		})
	}

	const cancelButtonClicked = (event) => {
		event.preventDefault()

		setDisabled(false)
		setDisableBackdropClick(false)
		setDisableEscapeKeyDown(false)

		onCancel()
		modal.Close()
	}

	return (
		<modal.Component
			style={{width: "100%"}}
			disableBackdropClick={disableBackdropClick}
			disableEscapeKeyDown={disableEscapeKeyDown}
		>
			<Grid container direction="column" spacing={3}>
				<Grid
					container
					direction="row"
					spacing={3}
					style={{alignItems: "center", justifyContent: "flex-start"}}
				>
					<Grid item xs={9} sm={10} style={{paddingLeft: "12px", paddingTop: "18px"}}>
						<Typography variant="subtitle1" style={{fontWeight: "bold", lineHeight: 1.3}} align="left">
							{i18n.EFTAModalTitle}
						</Typography>
					</Grid>
					<Grid item xs={3} sm={2} style={{display: "flex", justifyContent: "flex-end"}}>
						<modal.CloseIconButton disabled={disabled} />
					</Grid>
				</Grid>
				<Grid item style={{paddingLeft: "6px"}}>
					<TextToHTML text={i18n.EFTAModalText?.replace("${last4CardDigits}", last4CardDigits)} />
				</Grid>
				<Grid item>
					<Button fullWidth type="submit" onClick={submitButtonClicked} disabled={disabled}>
						{i18n.EFTAModalPrimaryButtonLabel}
					</Button>
				</Grid>
				<Grid item>
					<Button fullWidth variant="outlined" onClick={cancelButtonClicked} disabled={disabled}>
						{i18n.EFTAModalSecondaryButtonLabel}
					</Button>
				</Grid>
				<Grid item>
					<Typography color="textSecondary">
						<TextToHTML text={i18n.EFTAModalConditionText} />
					</Typography>
				</Grid>
			</Grid>
		</modal.Component>
	)
}

export {EFTAConfirmationDialog}
