import React from "react"
import styled from "styled-components"
import {Typography, Grid} from "@material-ui/core"
import {Page} from "@indebted/components/Page"
import {InvalidCodeImage} from "@indebted/assets"
import {TextToHTML} from "@indebted/components/TextToHTML"

function SecureCodeNotFound({i18n}) {
	return (
		<Page>
			<Grid item>
				<Typography align="center" variant="h6">
					{i18n.Title}
				</Typography>
			</Grid>

			<Grid item>
				<TextToHTML align="center" text={i18n.Help} />
			</Grid>

			<Grid item>
				<TextToHTML align="center" text={i18n.Message} />
			</Grid>

			<GridImage item>
				<InvalidCodeImage width="20em" />
			</GridImage>
		</Page>
	)
}

const GridImage = styled(Grid)`
	margin: ${(props) => props.theme.spacing(5)}px;
`

export {SecureCodeNotFound}
