import React from "react"
import {Page} from "@indebted/components/Page"
import {Grid, Typography} from "@material-ui/core"
import {PaymentCompletedImage} from "@indebted/assets"
import {Prop, PropLabel, Props, PropValue} from "@indebted/components/Props"
import styled from "styled-components"

function PaymentSucceeded({ClientName, Amount, I18n}) {
	return (
		<Page>
			<Grid item>
				<Typography align="center" variant="h6">
					{I18n.PaymentSucceeded.Title}
				</Typography>
			</Grid>

			<Grid item>
				<Typography align="center">{I18n.PaymentSucceeded.Subtitle}</Typography>
			</Grid>

			<GridImage item>
				<PaymentCompletedImage width="20em" />
			</GridImage>

			<Props>
				<Prop>
					<PropLabel align="right">{I18n.PaymentSucceeded.PaymentAmountLabel}</PropLabel>
					<PropValue align="left">{Amount}</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{I18n.PaymentSucceeded.PaidToLabel}</PropLabel>
					<PropValue align="left" className="clarity-mask">
						{ClientName}
					</PropValue>
				</Prop>
			</Props>
		</Page>
	)
}

const GridImage = styled(Grid)`
	margin: ${(props) => props.theme.spacing(5)}px;
`

export {PaymentSucceeded}
