import {Page} from "@indebted/components/Page"
import {Grid, Typography} from "@material-ui/core"
import {InvalidCodeImage} from "@indebted/assets"
import React from "react"
import styled from "styled-components"

function Invalid() {
	return (
		<Page>
			<Grid item>
				<Typography align="center" variant="h6">
					Unexpected result
				</Typography>
			</Grid>

			<GridImage item>
				<InvalidCodeImage width="20em" />
			</GridImage>
		</Page>
	)
}

const GridImage = styled(Grid)`
	margin: ${(props) => props.theme.spacing(5)}px;
`

export {Invalid}
