import React from "react"
import styled from "styled-components"
import {Page} from "@indebted/components/Page"
import {Grid, Typography, Button} from "@material-ui/core"
import {track} from "@indebted/analytics"
import {useParams} from "@reach/router"

function FrequencySelection({i18n, navigate, frequencies}) {
	const params = new URLSearchParams(location.search)
	const {secureCode} = useParams()
	track("PPFrequencyPageViewed", {
		SecureCode: secureCode,
	})
	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" justify="space-between">
				<Grid container item direction="column" alignItems="stretch" spacing={5}>
					<Grid item>
						<Typography variant="h6">{i18n.Header}</Typography>
					</Grid>
					<Grid item>
						<Typography>{i18n.Title}</Typography>
					</Grid>
				</Grid>

				<ButtonsGrid container item direction="column" alignItems="stretch" spacing={5}>
					<Grid item>
						<Typography>{i18n.Question}</Typography>
					</Grid>

					{frequencies.map((frequency) => (
						<Grid item key={frequency.ID}>
							<Button
								fullWidth
								variant="outlined"
								onClick={() => {
									track("PPFrequencyButtonClicked", {
										Frequency: frequency.ID,
										SecureCode: secureCode,
									})
									navigate(`${frequency.ID}?${params}`)
								}}
							>
								{frequency.DisplayText}
							</Button>
						</Grid>
					))}
				</ButtonsGrid>
			</Grid>
		</Page>
	)
}

const ButtonsGrid = styled(Grid)`
	margin-top: 20px;
`

export {FrequencySelection}
