import React from "react"
import {Page} from "@indebted/components/Page"
import {Grid, Typography} from "@material-ui/core"
import {StripeAuBankAccountForm, StripeAuBankAccountDisclaimer} from "@indebted/stripe"

function AuDirectDebitForm({i18n, locale, onSubmit, publishableKey}) {
	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item>
					<Typography variant="h6">{i18n.Header}</Typography>
				</Grid>
				<Grid item>
					<Typography>{i18n.Title}</Typography>
				</Grid>

				<Grid item>
					<StripeAuBankAccountForm
						i18n={i18n}
						locale={locale}
						onSubmit={onSubmit}
						publishableKey={publishableKey}
						paymentMethodSelectionLink={`PaymentMethodSelection/${location.search}`}
					/>
				</Grid>

				<Grid item>
					<StripeAuBankAccountDisclaimer />
				</Grid>
			</Grid>
		</Page>
	)
}

export {AuDirectDebitForm}
