import React from "react"
import styled from "styled-components"
import {Typography, Grid} from "@material-ui/core"
import {Page} from "@indebted/components/Page"
import {PaymentProcessingImage} from "@indebted/assets"
import {Props, Prop, PropValue, PropLabel} from "@indebted/components/Props"
import {TextToHTML} from "@indebted/components/TextToHTML"
import {DiscountMessage} from "@indebted/components/DiscountMessage"
import {CalendarModal} from "@indebted/components/AddToCalendarModal"

function InPaymentPlan({
	i18n,
	ClientName,
	PaymentPlanInstalmentAmount,
	PaymentPlanFrequency,
	PaymentPlanStartDate,
	PaymentPlanEndDate,
	CalendarURLs,
}) {
	return (
		<Page>
			<Grid item>
				<DiscountMessage discountMessage={i18n.DiscountMessage} />
			</Grid>
			<Grid item>
				<Typography align="center" variant="h6">
					{i18n.Title}
				</Typography>
			</Grid>

			<Grid item>
				<TextToHTML align="center" text={i18n.Message} />
			</Grid>

			<GridImage item>
				<PaymentProcessingImage width="20em" />
			</GridImage>

			{CalendarURLs && (
				<CalendarModal
					CalendarURLs={CalendarURLs}
					openCalendarModal={false}
					headingLabel={i18n.AddToCalendarModalHeading}
					buttonLabel={i18n.AddToCalendarButtonLabel}
				/>
			)}

			<Props>
				<Prop>
					<PropLabel align="right">{i18n.Label}</PropLabel>
					<PropValue align="left">
						{PaymentPlanInstalmentAmount} {PaymentPlanFrequency}
					</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{i18n.PaidToLabel}</PropLabel>
					<PropValue align="left" className="clarity-mask">
						{ClientName}
					</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{i18n.StartLabel}</PropLabel>
					<PropValue align="left">{PaymentPlanStartDate}</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{i18n.EndLabel}</PropLabel>
					<PropValue align="left">{PaymentPlanEndDate}</PropValue>
				</Prop>
			</Props>
		</Page>
	)
}

const GridImage = styled(Grid)`
	margin: ${(props) => props.theme.spacing(5)}px;
`

export {InPaymentPlan}
