import React from "react"
import DateFnsUtils from "@date-io/date-fns"
import {Calendar as MUICalendar, MuiPickersUtilsProvider} from "@material-ui/pickers"

import {locales} from "./locales"

function Calendar({locale, ...props}) {
	return (
		<MuiPickersUtilsProvider locale={locales[locale]} utils={DateFnsUtils}>
			<MUICalendar {...props} />
		</MuiPickersUtilsProvider>
	)
}

export {Calendar}
