import React, {useEffect, useState} from "react"
import {
	CardElement as StripeCardElement,
	Elements,
	PaymentRequestButtonElement,
	useElements,
} from "@stripe/react-stripe-js"
import {Button, FormControl, FormHelperText, Grid, InputLabel, Link, OutlinedInput, Typography} from "@material-ui/core"
import {useNotification} from "@indebted/components/Notification"
import {AcceptedCards} from "@indebted/components/AcceptedCards"
import {TermsOfDiscountOffer} from "@indebted/components/TermsOfDiscountOffer"
import {BankAccountIcon} from "@indebted/assets"
import {useParams} from "@reach/router"

import "regenerator-runtime/runtime"
import {Element} from "./Element"
import {Api} from "./api"
import {initWalletPay} from "./walletPay"

function DebitCardForm({stripe, locale, ...props}) {
	return (
		<Elements stripe={stripe} options={{locale}}>
			<InnerForm stripe={stripe} {...props} />
		</Elements>
	)
}

function InnerForm({
	stripe,
	onSubmit,
	i18n,
	termsOfDiscountOffer,
	hidePostalCode,
	acceptedCardsList,
	morePaymentMethodsAvailable,
	walletPay,
	clientSecret,
	walletPayMethods,
	paymentMethodSelectionLink,
	eventPrefix,
	statusURL,
}) {
	const {notification} = useNotification()
	const [submitting, setSubmitting] = useState(false)
	const [form, setForm] = useState({Email: "", Name: ""})
	const elements = useElements()
	const [paymentRequest, setPaymentRequest] = useState(null)
	const {secureCode} = useParams()

	const handleInput =
		(name) =>
		({target: {value}}) => {
			setForm({...form, [name]: value})
		}

	const debitCardElement = elements.getElement(StripeCardElement)

	const stripeAPI = Api({
		stripeSDK: stripe,
		form,
		debitCardElement,
		fundingTypeErrorMessage: i18n.FormErrorsFundingType,
	})

	// eslint-disable-next-line complexity
	const submit = (event) => {
		event.preventDefault()

		// See https://stripe.com/docs/stripe-js/react#useelements-hook
		if (!stripe || !elements) {
			return
		}

		const emailValidationRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (form.Name.trim() == "" || !form.Email.match(emailValidationRegex)) {
			notification.error(i18n.FormErrorsEmailNameValidation, 5000)
			return
		}

		setSubmitting(true)

		onSubmit({
			stripeAPI,
		}).catch(() => setSubmitting(false))
	}

	useEffect(
		() => {
			initWalletPay({
				walletPayMethods,
				stripe,
				stripeAPI,
				walletPay,
				secureCode,
				clientSecret,
				i18n,
				eventPrefix,
				notification,
				statusURL,
				setPaymentRequest,
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	return (
		<form onSubmit={submit}>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item container>
					<Grid container direction="column" alignItems="stretch" spacing={5}>
						<Grid item>
							<FormControl variant="outlined" fullWidth={true}>
								<InputLabel htmlFor="fullname">{i18n.FormFullNameLabel}</InputLabel>
								<OutlinedInput
									id="fullname"
									type="text"
									label={i18n.FormFullNameLabel}
									value={form.Name}
									onChange={handleInput("Name")}
									required
									autoFocus
								/>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl variant="outlined" fullWidth={true}>
								<InputLabel htmlFor="email">{i18n.FormEmailLabel}</InputLabel>
								<OutlinedInput
									id="email"
									label={i18n.FormEmailLabel}
									type="email"
									value={form.Email}
									onChange={handleInput("Email")}
									variant="outlined"
									required
								/>
								<FormHelperText>{i18n.FormEmailHelp}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item>
							<Element hidePostalCode={hidePostalCode} />
							<AcceptedCards list={acceptedCardsList} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Typography variant="caption" color="textSecondary" component="p" align="center">
						{i18n.FormTermsAndConditionsText}{" "}
						<Link href={i18n.FormTermsAndConditionsLink} target="_blank" rel="noopener noreferrer">
							{i18n.FormTermsAndConditionsLinkText}
						</Link>
						{termsOfDiscountOffer?.AndText}
						<TermsOfDiscountOffer linkLabel={termsOfDiscountOffer?.ModalLinkLabel} />
					</Typography>
				</Grid>
				<Grid item>
					<Button fullWidth type="submit" disabled={!stripe || submitting}>
						{i18n.FormButtonLabel}
					</Button>
				</Grid>
				<Grid item>{paymentRequest && <PaymentRequestButtonElement options={{paymentRequest}} />}</Grid>
				{morePaymentMethodsAvailable && (
					<Grid item>
						<Grid container direction="row" alignItems="flex-start" spacing={1}>
							<Grid item xs={1} style={{paddingTop: "3px", paddingRight: "5px", maxWidth: "30px"}}>
								<BankAccountIcon />
							</Grid>
							<Grid item xs={11}>
								<Typography>
									{i18n.FormPaymentMethodSelectionText}
									<Link href={paymentMethodSelectionLink} rel="noopener noreferrer">
										{i18n.FormPaymentMethodSelectionLinkText}
									</Link>
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		</form>
	)
}

export {DebitCardForm}
