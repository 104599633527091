import React from "react"
import {Page} from "@indebted/components/Page"
import {Grid, Typography} from "@material-ui/core"
import {StripeDebitCardWithEFTAForm} from "@indebted/stripe"

function DebitCardWithEFTAForm({
	i18n,
	locale,
	onSubmitEFTA,
	publishableKey,
	requiresPostcode,
	acceptedCardsList,
	alternativePaymentMethod,
	morePaymentMethodsAvailable,
	walletPay,
	walletPayMethods,
	clientSecret,
	providerData,
	eventPrefix,
	statusURL,
	secureCode,
	useNewDigitalWalletFlow,
}) {
	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item>
					<Typography variant="h6">{i18n.Header}</Typography>
				</Grid>
				<Grid item>
					<Typography>{i18n.Title}</Typography>
				</Grid>

				<Grid item>
					<StripeDebitCardWithEFTAForm
						i18n={i18n}
						locale={locale}
						onSubmitEFTA={onSubmitEFTA}
						publishableKey={publishableKey}
						hidePostalCode={!requiresPostcode}
						acceptedCardsList={acceptedCardsList}
						alternativePaymentMethod={alternativePaymentMethod}
						morePaymentMethodsAvailable={morePaymentMethodsAvailable}
						walletPay={walletPay}
						walletPayMethods={walletPayMethods}
						clientSecret={clientSecret}
						providerData={providerData}
						paymentMethodSelectionLink={`/${secureCode}/partial-payment/PaymentMethodSelection/${location.search}`}
						eventPrefix={eventPrefix}
						statusURL={statusURL}
						useNewDigitalWalletFlow={useNewDigitalWalletFlow}
					/>
				</Grid>
			</Grid>
		</Page>
	)
}

export {DebitCardWithEFTAForm}
