import React from "react"
import {Page} from "@indebted/components/Page"
import {
	Button,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core"
import {TextToHTML} from "@indebted/components/TextToHTML"
function AcceptedWithSuggestions({
	Greeting,
	Suggestion,
	Disclaimer,
	ContinueButtonLabel,
	id,
	navigate,
	PaymentOptionType,
	secureCode,
}) {
	return (
		<Page>
			<Grid item>
				<TextToHTML variant="body2" align="justify" text={Greeting} />
			</Grid>

			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							{Suggestion[0].map((cell, i) => (
								<TableCell key={i}>{cell}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{Suggestion.slice(1).map((row, i) => (
							<TableRow key={i}>
								{row.map((cell, i) => (
									<TableCell key={i}>{cell}</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Grid item>
				<Typography variant="body2" align="justify">
					{Disclaimer}
				</Typography>
			</Grid>

			<Grid item alignItems="stretch">
				<Button
					fullWidth
					variant="outlined"
					onClick={() => navigate(`/${secureCode}/${PaymentOptionType}?aaid=${id}`)}
				>
					{ContinueButtonLabel}
				</Button>
			</Grid>
		</Page>
	)
}

export {AcceptedWithSuggestions}
